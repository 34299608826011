:where(:root[data-uinew]) {
	--brand-vivid-blue-1000: rgba(0, 108, 253, 100%);
	--neutral-000: rgba(255, 255, 255, 100%);
	--neutral-1000: rgba(0, 0, 0, 100%);
	--neutral-300: rgba(196, 196, 196, 100%);
	--neutral-400: rgba(158, 158, 158, 100%);
	--neutral-900: rgba(1, 1, 1, 100%);
	--neutral-500: rgba(102, 102, 102, 100%);
	--neutral-100: rgba(237, 237, 237, 100%);
	--font-family-primary: roboto flex;
	--neutral-600: rgba(64, 64, 64, 100%);
	--neutral-800: rgba(33, 33, 33, 100%);
	--font-numeric-300: 12px;
	--font-numeric-400: 16px;
	--font-numeric-500: 20px;
	--font-numeric-600: 24px;
	--font-numeric-800: 32px;
	--font-numeric-350: 14px;
	--font-numeric-250: 10px;
	--font-size-250: var(--font-numeric-250);
	--font-size-275: var(--font-numeric-300);
	--font-size-350: var(--font-numeric-350);
	--font-size-400: var(--font-numeric-400);
	--font-line-height-350: var(--font-numeric-350);
	--font-numeric-10000: 400;
	--font-numeric-12500: 500;
	--font-numeric-15000: 600;
	--font-numeric-1000: 40px;
	--font-size-500: var(--font-numeric-500);
	--font-size-600: var(--font-numeric-600);
	--font-size-800: var(--font-numeric-800);
	--font-line-height-400: var(--font-numeric-400);
	--font-line-height-500: var(--font-numeric-500);
	--font-line-height-600: var(--font-numeric-600);
	--font-line-height-800: var(--font-numeric-800);
	--font-line-height-1000: var(--font-numeric-1000);
	--font-font-weight-10000: var(--font-numeric-10000);
	--font-font-weight-12500: var(--font-numeric-12500);
	--font-font-weight-15000: var(--font-numeric-15000);
	--neutral-1000-opacity-20: rgba(0, 0, 0, 20%);
	--numeric-050: 2px;
	--numeric-100: 4px;
	--numeric-200: 8px;
	--numeric-025: 1px;
	--numeric-0125: 0.5px;
	--numeric-300: 12px;
	--numeric-400: 16px;
	--numeric-500: 20px;
	--numeric-600: 24px;
	--numeric-700: 28px;
	--numeric-800: 32px;
	--numeric-1000: 40px;
	--numeric-1200: 48px;
	--numeric-1600: 64px;
	--border-radius-lg: var(--numeric-300);
	--border-width-sm: var(--numeric-025);
	--space-050: var(--numeric-050);
	--border-radius-sm: var(--numeric-100);
	--border-radius-md: var(--numeric-200);
	--border-radius-xl: var(--numeric-400);
	--border-width-xs: var(--numeric-0125);
	--space-100: var(--numeric-100);
	--space-200: var(--numeric-200);
	--space-300: var(--numeric-300);
	--space-400: var(--numeric-400);
	--space-500: var(--numeric-500);
	--space-600: var(--numeric-600);
	--space-700: var(--numeric-700);
	--space-800: var(--numeric-800);
	--space-1000: var(--numeric-1000);
	--space-1200: var(--numeric-1200);
	--numeric-2800: 112px;
	--border-radius-full: var(--numeric-2800);
	--border-radius-xs: var(--numeric-050);
	--system-red-1000: rgba(225, 113, 129, 100%);
	--system-green-1000: rgba(92, 191, 166, 100%);
	--system-yellow-1000: rgba(247, 204, 126, 100%);
	--system-blue-1000: rgba(0, 143, 254, 100%);
	--font-line-height-275: var(--font-numeric-300);
	--neutral-050: rgba(245, 245, 245, 100%);
	--font-numeric-700: 28px;
	--font-line-height-700: var(--font-numeric-700);
	--system-purple-1000: rgba(202, 105, 240, 100%);
	--neutral-700: rgba(51, 51, 51, 100%);
	--neutral-200: rgba(226, 226, 226, 100%);
	--system-blue-200: rgba(204, 233, 255, 100%);
	--system-green-350: rgba(198, 233, 224, 100%);
	--system-red-400: rgba(243, 198, 205, 100%);
	--system-yellow-350: rgba(252, 237, 210, 100%);
	--height-xs: var(--numeric-600);
	--height-sm: var(--numeric-800);
	--height-md: var(--numeric-1000);
	--brand-metal-90: rgba(244, 246, 248, 100%);
	--brand-metal-1000: rgba(132, 150, 179, 100%);
	--brand-metal-500: rgba(193, 203, 217, 100%);
	--brand-metal-140: rgba(238, 240, 244, 100%);
	--font-size-1000: var(--font-numeric-1000);
	--font-line-height-1200: var(--font-numeric-1200);
	--font-numeric-1200: 48px;
	--brand-vivid-blue-100: rgba(229, 240, 255, 100%);
	--brand-vivid-blue-800: rgba(51, 137, 253, 100%);
	--brand-metal-300: rgba(218, 223, 232, 100%);
	--system-red-550: rgba(239, 177, 186, 100%);
	--font-numeric-525: 21px;
	--font-line-height-525: var(--font-numeric-525);
	--brand-dark-grey-1000: rgba(50, 56, 70, 100%);
	--brand-dark-grey-800: rgba(91, 96, 107, 100%);
	--brand-metal-50: rgba(249, 250, 251, 100%);
	--system-dark-red-1000: rgba(214, 61, 84, 100%);
	--system-blue-350: rgba(166, 204, 254, 100%);
	--border-width-focus: var(--numeric-050);
	--system-dark-green-1000: rgba(50, 129, 109, 100%);
	--min-width-xs: var(--numeric-1600);
	--min-width-sm: var(--numeric-2800);
	--min-width-md: 164px;
	--min-width-lg: 264px;
	--space-000: 0px;
	--system-dark-blue-1000: rgba(0, 114, 203, 100%);
	--system-dark-yellow-1000: rgba(201, 158, 79, 100%);


	[data-uitheme="light"], &[data-uitheme="light"] {
		--surface-neutral-subtlest: var(--neutral-000);
		--icon-primary: var(--neutral-900);
		--text-primary: var(--neutral-900);
		--text-secondary: var(--neutral-500);
		--text-disabled: var(--neutral-300);
		--icon-primary-inverted: var(--neutral-000);
		--text-primary-inverted: var(--neutral-000);
		--fill-disabled: var(--neutral-200);
		--border-disabled: var(--neutral-300);
		--icon-disabled: var(--neutral-300);
		--icon-secondary: var(--neutral-500);
		--surface-overlay: var(--neutral-1000-opacity-20);
		--fill-system-info-subtler: var(--system-blue-200);
		--border-system-danger-bold: var(--system-red-1000);
		--border-system-success-boldest: var(--system-green-1000);
		--icon-status-not-approved: var(--system-red-1000);
		--icon-status-approved: var(--system-green-1000);
		--icon-status-in-progress: var(--system-yellow-1000);
		--icon-status-for-review: var(--system-blue-1000);
		--icon-status-no-status: var(--neutral-400);
		--fill-system-recording: var(--system-red-1000);
		--surface-neutral-subtler: var(--neutral-050);
		--fill-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--text-negative-number: var(--system-dark-red-1000);
		--text-positive-number: var(--system-dark-green-1000);
		--text-brand: var(--brand-vivid-blue-1000);
		--icon-brand: var(--brand-vivid-blue-1000);
		--text-link: var(--brand-vivid-blue-1000);
		--text-link-pressed: var(--system-blue-1000);
		--border-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--border-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-subtlest: var(--neutral-000);
		--text-link-hover: var(--system-blue-1000);
		--fill-neutral-boldest: var(--neutral-600);
		--fill-brand-secondary-subtler: var(--brand-metal-90);
		--fill-brand-secondary-subtle: var(--brand-metal-140);
		--fill-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-bold: var(--neutral-400);
		--fill-brand-primary-subtlest: var(--brand-vivid-blue-100);
		--fill-system-danger-subtler: var(--system-red-400);
		--fill-system-success-subtler: var(--system-green-350);
		--fill-system-warning-subtler: var(--system-yellow-350);
		--border-system-info-boldest: var(--system-blue-1000);
		--border-system-warning-boldest: var(--system-yellow-1000);
		--surface-transparent: rgba(255, 255, 255, 0%);
		--fill-transparent: rgba(255, 255, 255, 0%);
		--border-brand-secondary-boldest: var(--brand-metal-1000);
		--border-brand-secondary-bold: var(--brand-metal-500);
		--fill-brand-secondary-neutral: var(--brand-metal-300);
		--border-neutral-bolder: var(--neutral-600);
		--border-neutral-subtle: var(--neutral-100);
		--fill-system-danger-subtle: var(--system-red-550);
		--fill-brand-secondary-subtlest: var(--brand-metal-50);
		--fill-system-danger-bold: var(--system-dark-red-1000);
		--text-error: var(--system-dark-red-1000);
		--icon-error: var(--system-dark-red-1000);
		--border-system-danger-boldest: var(--system-dark-red-1000);
		--border-system-focus: var(--system-blue-350);
		--fill-neutral-subtle: var(--neutral-100);
		--border-transparent: rgba(255, 255, 255, 0%);
		--fill-system-info-bold: var(--system-dark-blue-1000);
		--fill-system-success-bold: var(--system-dark-green-1000);
		--fill-system-warning-bold: var(--system-dark-yellow-1000);
	}

	[data-uitheme="dark"], &[data-uitheme="dark"] {
		--surface-neutral-subtlest: var(--neutral-800);
		--icon-primary: var(--neutral-000);
		--text-primary: var(--neutral-000);
		--text-secondary: var(--neutral-200);
		--text-disabled: var(--neutral-500);
		--icon-primary-inverted: var(--neutral-900);
		--text-primary-inverted: var(--neutral-900);
		--fill-disabled: var(--neutral-500);
		--border-disabled: var(--neutral-500);
		--icon-disabled: var(--neutral-500);
		--icon-secondary: var(--neutral-200);
		--surface-overlay: var(--neutral-1000-opacity-20);
		--fill-system-info-subtler: var(--system-blue-1000);
		--border-system-danger-bold: var(--system-red-550);
		--border-system-success-boldest: var(--system-green-350);
		--icon-status-not-approved: var(--system-red-1000);
		--icon-status-approved: var(--system-green-1000);
		--icon-status-in-progress: var(--system-yellow-1000);
		--icon-status-for-review: var(--system-blue-1000);
		--icon-status-no-status: var(--neutral-300);
		--fill-system-recording: var(--system-red-1000);
		--surface-neutral-subtler: var(--neutral-600);
		--fill-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--text-negative-number: var(--system-dark-red-1000);
		--text-positive-number: var(--system-green-1000);
		--text-brand: var(--brand-vivid-blue-1000);
		--icon-brand: var(--brand-vivid-blue-1000);
		--text-link: var(--brand-vivid-blue-1000);
		--text-link-pressed: var(--system-blue-1000);
		--border-brand-primary-boldest: var(--brand-vivid-blue-1000);
		--border-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-subtlest: var(--neutral-600);
		--text-link-hover: var(--system-blue-1000);
		--fill-neutral-boldest: var(--neutral-000);
		--fill-brand-secondary-subtler: var(--brand-dark-grey-1000);
		--fill-brand-secondary-subtle: var(--brand-dark-grey-800);
		--fill-brand-primary-boldest-hover: var(--brand-vivid-blue-800);
		--fill-neutral-bold: var(--neutral-200);
		--fill-brand-primary-subtlest: var(--brand-vivid-blue-100);
		--fill-system-danger-subtler: var(--system-red-1000);
		--fill-system-success-subtler: var(--system-green-1000);
		--fill-system-warning-subtler: var(--system-yellow-1000);
		--border-system-info-boldest: var(--system-blue-200);
		--border-system-warning-boldest: var(--system-yellow-350);
		--surface-transparent: rgba(0, 0, 0, 0%);
		--fill-transparent: rgba(255, 255, 255, 0%);
		--border-brand-secondary-boldest: var(--brand-dark-grey-1000);
		--border-brand-secondary-bold: var(--brand-dark-grey-800);
		--fill-brand-secondary-neutral: var(--brand-metal-300);
		--border-neutral-bolder: var(--neutral-100);
		--border-neutral-subtle: var(--neutral-600);
		--fill-system-danger-subtle: var(--system-red-550);
		--fill-brand-secondary-subtlest: var(--brand-dark-grey-1000);
		--fill-system-danger-bold: var(--system-dark-red-1000);
		--text-error: var(--system-dark-red-1000);
		--icon-error: var(--system-dark-red-1000);
		--border-system-danger-boldest: var(--system-red-400);
		--border-system-focus: var(--system-blue-350);
		--fill-neutral-subtle: var(--neutral-400);
		--border-transparent: rgba(255, 255, 255, 0%);
		--fill-system-info-bold: var(--system-blue-1000);
		--fill-system-success-bold: var(--system-green-1000);
		--fill-system-warning-bold: var(--system-yellow-1000);
	}

	[data-uienv="platform"], &[data-uienv="platform"] {
		--button-fill-primary: var(--fill-brand-primary-boldest);
		--button-fill-secondary: var(--fill-brand-secondary-subtler);
		--button-fill-primary-disabled: var(--fill-disabled);
		--button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--forms-border-primary-enabled: var(--border-brand-secondary-boldest);
		--forms-border-primary-disabled: var(--border-disabled);
		--forms-border-primary-selected: var(--border-brand-primary-boldest);
		--forms-border-primary-hover: var(--border-brand-primary-boldest-hover);
		--forms-border-primary-filled: var(--border-neutral-bolder);
		--forms-border-primary-error: var(--border-system-danger-boldest);
		--forms-fill-primary-enabled: var(--fill-neutral-subtlest);
		--table-fill-hover: var(--fill-brand-secondary-subtler);
		--table-fill-even: var(--fill-neutral-subtlest);
		--menu-item-fill-primary-default: var(--fill-neutral-subtlest);
		--menu-item-fill-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-item-fill-primary-selected: var(--fill-brand-secondary-subtle);
		--slider-fill-active: var(--fill-brand-primary-boldest);
		--slider-fill: var(--fill-neutral-bold);
		--toggle-fill-selected: var(--fill-brand-primary-boldest);
		--toggle-fill-default: var(--fill-neutral-bold);
		--snackbar-fill-info: var(--fill-system-info-subtler);
		--snackbar-fill-warning: var(--fill-system-warning-subtler);
		--snackbar-fill-success: var(--fill-system-success-subtler);
		--snackbar-fill-error: var(--fill-system-danger-subtler);
		--snackbar-border-info: var(--border-system-info-boldest);
		--snackbar-border-success: var(--border-system-success-boldest);
		--snackbar-border-warning: var(--border-system-warning-boldest);
		--snackbar-border-error: var(--border-system-danger-boldest);
		--button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--button-fill-secondary-pressed: var(--fill-brand-secondary-neutral);
		--list-item-rich-fill-default: var(--fill-neutral-subtlest);
		--list-item-rich-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-rich-fill-selected: var(--fill-brand-secondary-subtle);
		--table-fill-odd: var(--fill-brand-secondary-subtlest);
		--button-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--tabs-fill: var(--fill-brand-secondary-subtler);
		--tabs-item-fill-primary-selected: var(--fill-brand-primary-boldest);
		--tabs-item-fill-primary-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill: var(--fill-transparent);
		--floating-button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--floating-button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-border-primary-default: var(--border-brand-secondary-bold);
		--button-fill-primary-destructive: var(--system-dark-red-1000);
		--floating-button-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill-secondary-pressed: var(--fill-brand-secondary-neutral);
		--list-item-rich-fill-divider: var(--border-brand-secondary-bold);
		--calendar-item-fill: var(--fill-neutral-subtlest);
		--calendar-item-fill-hover: var(--fill-brand-secondary-subtle);
		--calendar-item-fill-selected: var(--fill-brand-primary-boldest);
		--calendar-item-fill-selected-span: var(--fill-brand-primary-subtlest);
		--button-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--list-item-rich-fill-pressed: var(--fill-brand-secondary-neutral);
		--table-fill-pressed: var(--fill-brand-secondary-neutral);
		--tabs-item-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-fill-destructive-hover: var(--system-dark-red-1000);
		--button-border-secondary-destructive: var(--system-red-1000);
		--button-fill-tertiary: var(--fill-neutral-subtlest);
		--button-fill-tertiary-hover: var(--fill-brand-secondary-subtler);
		--button-fill-tertiary-pressed: var(--fill-brand-secondary-neutral);
		--button-fill-tertiary-selected: var(--fill-brand-secondary-subtler);
		--toggle-fill-disabled: var(--fill-disabled);
		--button-fill-primary-destructive-hover: var(--fill-neutral-subtlest);
		--button-text-primary: var(--text-primary-inverted);
		--button-text-primary-inverted: var(--text-primary);
		--button-text-tetriary: var(--text-primary);
		--button-text-primary-destructive: var(--text-primary-inverted);
		--button-text-primary-destructive-inverted: var(--text-error);
		--button-text-brand: var(--text-brand);
		--button-text-disabled: var(--text-disabled);
		--button-icon-primary: var(--icon-primary-inverted);
		--button-icon-primary-inverted: var(--icon-primary);
		--button-icon-brand: var(--icon-brand);
		--button-icon-primary-destructive: var(--icon-primary-inverted);
		--button-icon-disabled: var(--icon-disabled);
		--button-text-secondary: var(--text-secondary);
		--button-icon-secondary: var(--icon-secondary);
		--button-icon-primary-destructive-inverted: var(--icon-error);
		--list-item-rich-border-default: var(--border-neutral-subtle);
		--forms-text-input-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-hover: var(--text-primary);
		--forms-text-input-primary-disabled: var(--text-disabled);
		--forms-text-input-primary-selected: var(--text-primary);
		--forms-text-help-primary-hover: var(--text-primary);
		--forms-text-help-primary-disabled: var(--text-disabled);
		--forms-text-help-primary-selected: var(--text-primary);
		--forms-text-help-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-error: var(--text-primary);
		--forms-text-help-primary-error: var(--text-error);
		--forms-text-input-primary-filled: var(--text-primary);
		--forms-text-help-primary-filled: var(--text-primary);
		--forms-icon-primary-enabled: var(--icon-secondary);
		--forms-icon-primary-hover: var(--icon-primary);
		--forms-icon-primary-disabled: var(--icon-disabled);
		--forms-icon-primary-selected: var(--icon-primary);
		--forms-icon-primary-filled: var(--icon-primary);
		--forms-icon-primary-error: var(--icon-primary);
		--chips-fill-seconadry-default: var(--fill-brand-secondary-subtler);
		--chips-fill-seconadry-selected: var(--fill-neutral-boldest);
		--chips-border-secondary-default: var(--border-brand-secondary-bold);
		--forms-fill-primary-hover: var(--fill-neutral-subtlest);
		--forms-fill-primary-selected: var(--fill-neutral-subtlest);
		--forms-fill-primary-filled: var(--fill-neutral-subtlest);
		--forms-fill-primary-disabled: var(--fill-neutral-subtlest);
		--forms-fill-primary-error: var(--fill-neutral-subtlest);
		--forms-text-suffix-secondary-enabled: var(--text-secondary);
		--forms-text-suffix-secondary-hover: var(--text-secondary);
		--forms-text-suffix-secondary-selected: var(--text-secondary);
		--forms-text-suffix-secondary-filled: var(--text-secondary);
		--forms-text-suffix-secondary-disabled: var(--text-disabled);
		--forms-text-suffix-secondary-error: var(--text-secondary);
		--forms-border-secondary-error: var(--border-system-danger-boldest);
		--forms-border-secondary-disabled: var(--border-disabled);
		--forms-border-secondary-selected: var(--border-brand-primary-boldest);
		--forms-border-secondary-hover: var(--border-brand-secondary-boldest);
		--forms-border-secondary-filled: var(--border-transparent);
		--forms-border-secondary-enabled: var(--border-brand-secondary-boldest);
		--forms-fill-secondary-error: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-disabled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-filled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-selected: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-hover: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-enabled: var(--fill-brand-secondary-subtler);
		--forms-icon-secondary-error: var(--icon-primary);
		--forms-icon-secondary-hover: var(--icon-primary);
		--forms-icon-secondary-disabled: var(--icon-disabled);
		--forms-icon-secondary-filled: var(--icon-primary);
		--forms-icon-secondary-selected: var(--icon-primary);
		--forms-icon-secondary-enabled: var(--icon-secondary);
		--forms-text-input-secondary-error: var(--text-primary);
		--forms-text-input-secondary-enabled: var(--text-secondary);
		--forms-text-input-secondary-selected: var(--text-primary);
		--forms-text-input-secondary-disabled: var(--text-disabled);
		--forms-text-input-secondary-filled: var(--text-primary);
		--forms-text-input-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-error: var(--text-error);
		--forms-text-help-secondary-selected: var(--text-primary);
		--forms-text-help-secondary-disabled: var(--text-disabled);
		--forms-text-help-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-filled: var(--text-primary);
		--forms-text-help-secondary-enabled: var(--text-secondary);
		--menu-item-fill-destructive-default: var(--fill-neutral-subtlest);
		--menu-item-fill-destructive-selected: var(--system-dark-red-1000);
		--menu-item-fill-destructive-pressed: var(--system-dark-red-1000);
		--menu-item-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-item-fill-disabled: var(--fill-neutral-subtlest);
		--menu-item-text-label-primary-default: var(--text-primary);
		--menu-item-text-label-primary-hover: var(--text-primary);
		--menu-item-text-label-primary-selected: var(--text-primary);
		--menu-item-text-label-primary-pressed: var(--text-primary-inverted);
		--menu-item-text-label-destructive-default: var(--text-error);
		--menu-item-text-label-destructive-hover: var(--text-primary-inverted);
		--menu-item-text-label-destructive-selected: var(--text-primary-inverted);
		--menu-item-text-label-destructive-pressed: var(--text-primary-inverted);
		--menu-item-text-label-disabled: var(--text-disabled);
		--menu-item-icon-label-primary-default: var(--icon-primary);
		--menu-item-icon-label-primary-hover: var(--icon-primary);
		--menu-item-icon-label-primary-selected: var(--icon-primary);
		--menu-item-icon-label-primary-pressed: var(--icon-primary-inverted);
		--menu-item-icon-label-destructive-default: var(--icon-error);
		--menu-item-icon-label-destructive-hover: var(--icon-primary-inverted);
		--menu-item-icon-label-destructive-selected: var(--icon-primary-inverted);
		--menu-item-icon-label-destructive-pressed: var(--icon-primary-inverted);
		--menu-item-icon-label-disabled: var(--icon-disabled);
		--menu-item-text-shortcut-disabled: var(--text-disabled);
		--menu-item-text-shortcut-destructive-pressed: var(--text-primary-inverted);
		--menu-item-text-shortcut-destructive-selected: var(--text-primary-inverted);
		--menu-item-text-shortcut-primary-pressed: var(--text-primary-inverted);
		--menu-item-text-shortcut-destructive-default: var(--text-error);
		--menu-item-text-shortcut-primary-selected: var(--text-primary);
		--menu-item-text-shortcut-primary-hover: var(--text-primary);
		--menu-item-text-shortcut-destructive-hover: var(--text-primary-inverted);
		--menu-item-text-shortcut-primary-default: var(--text-secondary);
		--menu-item-icon-shortcut-destructive-pressed: var(--icon-primary-inverted);
		--menu-item-icon-shortcut-destructive-hover: var(--icon-primary-inverted);
		--menu-item-icon-shortcut-destructive-default: var(--icon-error);
		--menu-item-icon-shortcut-disabled: var(--icon-disabled);
		--menu-item-icon-shortcut-primary-pressed: var(--icon-primary-inverted);
		--menu-item-icon-shortcut-primary-hover: var(--icon-primary);
		--menu-item-icon-shortcut-destructive-selected: var(--icon-primary-inverted);
		--menu-item-icon-shortcut-primary-selected: var(--icon-primary);
		--menu-item-icon-shortcut-primary-default: var(--icon-secondary);
		--menu-item-text-input-selected: var(--text-primary);
		--menu-item-text-input-hover: var(--text-primary);
		--menu-item-text-input-default: var(--text-secondary);
		--menu-item-icon-input-selected: var(--text-primary);
		--menu-item-icon-input-hover: var(--text-primary);
		--menu-item-icon-input-default: var(--text-secondary);
		--menu-search-fill-primary-default: var(--fill-neutral-subtlest);
		--menu-search-fill-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-search-fill-primary-selected: var(--fill-brand-secondary-subtlest);
		--menu-search-fill-primary-filled: var(--fill-brand-secondary-subtlest);
		--menu-search-fill-disabled: var(--fill-neutral-subtlest);
		--menu-search-text-primary-default: var(--text-secondary);
		--menu-search-text-primary-hover: var(--text-primary);
		--menu-search-text-primary-selected: var(--text-secondary);
		--menu-search-text-primary-filled: var(--text-primary);
		--menu-search-text-disabled: var(--text-disabled);
		--menu-search-icon-primary-default: var(--icon-secondary);
		--menu-search-icon-primary-hover: var(--icon-primary);
		--menu-search-icon-primary-selected: var(--icon-primary);
		--menu-search-icon-primary-filled: var(--icon-primary);
		--menu-search-icon-disabled: var(--icon-disabled);
		--menu-border-primary-selected: var(--border-brand-primary-boldest);
		--divider-fill-primary-default: var(--border-neutral-subtle);
		--scroll-fill-track-primary-enabled: var(--surface-transparent);
		--scroll-fill-thumb-primary-enabled: var(--fill-neutral-subtle);
		--scroll-fill-thumb-primary-hover: var(--fill-neutral-bold);
		--scroll-fill-thumb-primary-pressed: var(--fill-neutral-bold);
		--scroll-fill-track-primary-pressed: var(--surface-transparent);
		--scroll-fill-track-primary-hover: var(--surface-transparent);
		--scroll-fill-track-primary-disabled: var(--surface-neutral-subtler);
		--scroll-fill-thumb-primary-disabled: var(--fill-disabled);
		--menu-fill-primary-default: var(--surface-neutral-subtlest);
		--dialog-fill-primary-default: var(--surface-neutral-subtlest);
		--dialog-text-headline: var(--text-primary);
		--dialog-border-primary-default: var(--border-neutral-subtle);
		--dialog-icon-primary-default: var(--icon-primary);
		--list-item-rich-text-headline: var(--text-primary);
		--accordion-rich-border-default: var(--border-neutral-subtle);
		--accordion-rich-fill-default: var(--fill-neutral-subtlest);
		--accordion-rich-fill-hover: var(--fill-brand-secondary-subtler);
		--accordion-rich-fill-selected: var(--fill-brand-secondary-subtle);
		--accordion-rich-fill-divider: var(--border-brand-secondary-bold);
		--accordion-rich-text-headline: var(--text-primary);
		--accordion-rich-text-subheadline: var(--text-secondary);
		--accordion-simple-text-headline: var(--text-primary);
		--accordion-simple-text-body: var(--text-primary);
		--accordion-rich-text-body: var(--text-primary);
		--list-item-simple-fill-default: var(--fill-neutral-subtlest);
		--list-item-simple-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-simple-fill-selected: var(--fill-brand-secondary-subtle);
		--list-item-simple-fill-pressed: var(--fill-brand-secondary-neutral);
		--list-item-simple-text-default: var(--text-primary);
		--list-item-simple-text-hover: var(--text-primary);
		--list-item-simple-text-selected: var(--text-primary);
		--list-item-simple-text-pressed: var(--text-primary);
		--list-item-simple-icon-left-default: var(--icon-primary);
		--list-item-simple-icon-left-hover: var(--icon-primary);
		--list-item-simple-icon-left-selected: var(--icon-primary);
		--list-item-simple-icon-left-pressed: var(--icon-primary);
		--list-item-simple-icon-right-pressed: var(--icon-primary);
		--list-item-simple-icon-left-pressed-2: var(--icon-primary);
		--list-item-simple-icon-right-selected: var(--icon-primary);
		--list-item-simple-icon-left-selected-2: var(--icon-primary);
		--list-item-simple-icon-right-hover: var(--icon-primary);
		--list-item-simple-icon-left-hover-2: var(--icon-primary);
		--list-item-simple-icon-right-default: var(--icon-secondary);
		--grid-item-fill-default: var(--fill-transparent);
		--grid-item-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-simple-icon-left-default-2: var(--icon-primary);
		--grid-item-header-icon-default: var(--icon-primary);
		--grid-item-header-icon-hover: var(--icon-primary);
		--label-text-neutral: var(--text-primary);
		--label-text-brand: var(--text-brand);
		--label-text-secondary: var(--text-secondary);
		--label-text-disabled: var(--text-disabled);
		--label-text-inverted: var(--text-primary-inverted);
		--label-text-destructive: var(--text-error);
		--label-icon-primary: var(--icon-primary);
		--label-icon-brand: var(--icon-brand);
		--label-icon-secondary: var(--icon-secondary);
		--label-icon-disabled: var(--icon-disabled);
		--label-icon-inverted: var(--icon-primary-inverted);
		--label-icon-destructive: var(--icon-error);
		--snackbar-text-info: var(--text-primary);
		--snackbar-text-success: var(--text-primary);
		--snackbar-text-warning: var(--text-primary);
		--snackbar-text-error: var(--text-primary);
		--banner-fill-info: var(--fill-system-info-subtler);
		--banner-fill-warning: var(--fill-system-warning-subtler);
		--banner-fill-success: var(--fill-system-success-subtler);
		--banner-fill-error: var(--fill-system-danger-subtler);
		--banner-text-info: var(--text-primary);
		--banner-text-success: var(--text-primary);
		--banner-text-warning: var(--text-primary);
		--banner-text-error: var(--text-primary);
		--breadcrumb-fill-primary-default: var(--fill-brand-secondary-subtler);
		--breadcrumb-fill-primary-hover: var(--fill-brand-primary-subtlest);
		--breadcrumb-text-primary-default: var(--text-brand);
		--breadcrumb-text-primary-hover: var(--text-brand);
		--breadcrumb-text-primary-last: var(--text-brand);
		--breadcrumb-icon-chevron-primary-default: var(--icon-brand);
		--breadcrumb-icon-chevron-primary-hover: var(--icon-brand);
		--main-navigation-item-fill-default: var(--fill-transparent);
		--main-navigation-item-fill-hover: var(--fill-neutral-subtlest);
		--main-navigation-item-fill-active: var(--fill-neutral-subtlest);
		--main-navigation-item-text-default: var(--text-primary);
		--main-navigation-item-text-hover: var(--text-primary);
		--main-navigation-item-text-active: var(--text-brand);
		--main-navigation-item-icon-default: var(--icon-primary);
		--main-navigation-item-icon-hover: var(--icon-primary);
		--main-navigation-item-icon-active: var(--icon-brand);
		--main-navigation-fill-default: var(--surface-transparent);
		--table-fill-default: var(--fill-neutral-subtlest);
		--breadcrumb-fill-secondary-hover: var(--fill-transparent);
		--breadcrumb-fill-secondary-default: var(--fill-transparent);
		--breadcrumb-text-secondary-hover: var(--text-primary);
		--breadcrumb-text-secondary-default: var(--text-secondary);
		--breadcrumb-text-secondary-last: var(--text-primary);
		--breadcrumb-icon-chevron-secondary-hover: var(--icon-primary);
		--breadcrumb-icon-chevron-secondary-default: var(--icon-primary);
		--breadcrumb-icon-chevron-primary-last: var(--icon-brand);
		--breadcrumb-icon-chevron-secondary-last: var(--icon-primary);
		--breadcrumb-icon-left-secondary-last: var(--icon-primary);
		--breadcrumb-icon-left-primary-hover: var(--icon-brand);
		--breadcrumb-icon-left-secondary-hover: var(--icon-primary);
		--breadcrumb-icon-left-secondary-default: var(--icon-secondary);
		--breadcrumb-icon-left-primary-last: var(--icon-brand);
		--breadcrumb-icon-left-primary-default: var(--icon-brand);
		--sidebar-fill-default: var(--surface-neutral-subtlest);
		--sidebar-border-default: var(--border-neutral-subtle);
		--tabs-fill-primary: var(--fill-brand-secondary-subtler);
		--tabs-fill-secondary: var(--fill-neutral-subtlest);
		--tabs-item-fill-primary-enabled: var(--fill-brand-secondary-subtler);
		--tabs-item-fill-secondary-enabled: var(--fill-transparent);
		--tabs-item-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--tabs-item-icon-primary-enabled: var(--icon-primary);
		--tabs-item-icon-primary-selected: var(--icon-primary-inverted);
		--tabs-item-icon-primary-hover: var(--icon-brand);
		--tabs-item-icon-secondary-enabled: var(--icon-primary);
		--tabs-item-icon-secondary-selected: var(--icon-brand);
		--tabs-item-icon-secondary-hover: var(--icon-brand);
		--tabs-border-primary: var(--border-transparent);
		--tabs-border-secondary: var(--border-neutral-subtle);
		--paginator-fill-default: var(--surface-neutral-subtlest);
		--paginator-text-primary: var(--text-secondary);
		--chips-fill-primary-default: var(--fill-neutral-subtlest);
		--chips-fill-primary-selected: var(--fill-brand-primary-boldest);
		--chips-border-primary-default: var(--border-brand-secondary-bold);
		--chips-border-secondary-selected: var(--border-transparent);
		--chips-border-primary-selected: var(--border-transparent);
		--loader-fill-primary-light: var(--fill-brand-secondary-neutral);
		--loader-fill-primary-strong: var(--fill-brand-primary-boldest);
		--loader-fill-inverted-strong: var(--fill-neutral-subtlest);
		--loader-fill-inverted-light: var(--fill-brand-secondary-neutral);
		--skeleton-fill-default: var(--surface-neutral-subtlest);
		--tooltip-fill-default: var(--fill-neutral-boldest);
		--tooltip-text-default: var(--text-primary-inverted);
		--pill-border-info: var(--border-system-info-boldest);
		--pill-border-success: var(--border-system-success-boldest);
		--pill-border-warning: var(--border-system-warning-boldest);
		--pill-border-error: var(--border-system-danger-boldest);
		--pill-fill-info: var(--fill-system-info-subtler);
		--pill-fill-warning: var(--fill-system-warning-subtler);
		--pill-fill-success: var(--fill-system-success-subtler);
		--pill-fill-error: var(--fill-system-danger-subtler);
		--pill-text-info: var(--text-primary);
		--pill-text-success: var(--text-primary);
		--pill-text-warning: var(--text-primary);
		--pill-text-error: var(--text-primary);
		--toggle-fill-handle: var(--fill-neutral-subtlest);
		--stepper-item-icon-fill-done: var(--fill-neutral-boldest);
		--stepper-item-icon-fill-waiting: var(--fill-transparent);
		--stepper-item-icon-fill-active: var(--fill-brand-primary-boldest);
		--stepper-item-icon-text-done: var(--text-primary-inverted);
		--stepper-item-icon-text-waiting: var(--text-brand);
		--stepper-item-icon-text-active: var(--text-primary-inverted);
		--stepper-item-icon-icon-done: var(--icon-primary-inverted);
		--stepper-item-icon-border-active: var(--border-brand-primary-boldest);
		--button-group-fill-default: var(--fill-neutral-subtle);
		--button-group-item-text-enabled: var(--text-secondary);
		--button-group-item-text-selected: var(--text-primary);
		--button-group-item-text-disabled: var(--text-disabled);
		--button-group-item-icon-enabled: var(--icon-secondary);
		--active: var(--fill-brand-primary-boldest);
		--waiting: var(--fill-transparent);
		--done: var(--fill-neutral-boldest);
		--button-group-border-default: var(--border-neutral-subtle);
		--button-group-item-icon-selected: var(--icon-primary);
		--button-group-item-icon-disabled: var(--icon-disabled);
		--button-group-item-fill-enabled: var(--fill-transparent);
		--button-group-item-fill-selected: var(--fill-neutral-subtlest);
		--button-group-item-fill-disabled: var(--fill-transparent);
		--checkbox-item-icon-default: var(--icon-primary);
		--checkbox-item-icon-disabled: var(--icon-disabled);
		--checkbox-item-icon-hover: var(--icon-brand);
		--checkbox-item-checkbox-icon-checked: var(--icon-primary-inverted);
		--checkbox-item-checkbox-icon-hover: var(--icon-primary-inverted);
		--checkbox-item-checkbox-icon-disabled: var(--icon-primary-inverted);
		--checkbox-item-checkbox-fill-hover: var(--icon-brand);
		--checkbox-item-checkbox-fill-checked: var(--icon-primary);
		--checkbox-item-checkbox-fill-disabled: var(--icon-disabled);
	}

	[data-uienv="studio"], &[data-uienv="studio"] {
		--button-fill-primary: var(--fill-brand-primary-boldest);
		--button-fill-secondary: var(--fill-brand-secondary-subtle);
		--button-fill-primary-disabled: var(--fill-disabled);
		--button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--forms-border-primary-enabled: var(--border-brand-secondary-boldest);
		--forms-border-primary-disabled: var(--border-disabled);
		--forms-border-primary-selected: var(--border-brand-primary-boldest);
		--forms-border-primary-hover: var(--border-brand-secondary-boldest);
		--forms-border-primary-filled: rgba(255, 255, 255, 0%);
		--forms-border-primary-error: var(--border-system-danger-boldest);
		--forms-fill-primary-enabled: var(--fill-brand-secondary-subtler);
		--table-fill-hover: var(--fill-brand-secondary-subtler);
		--table-fill-even: var(--fill-neutral-subtlest);
		--menu-item-fill-primary-default: var(--fill-neutral-subtlest);
		--menu-item-fill-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-item-fill-primary-selected: var(--fill-brand-secondary-subtle);
		--slider-fill-active: var(--fill-neutral-boldest);
		--slider-fill: var(--fill-neutral-bold);
		--toggle-fill-selected: var(--fill-neutral-boldest);
		--toggle-fill-default: var(--fill-neutral-bold);
		--snackbar-fill-info: var(--fill-system-info-subtler);
		--snackbar-fill-warning: var(--fill-system-warning-subtler);
		--snackbar-fill-success: var(--fill-system-success-subtler);
		--snackbar-fill-error: var(--fill-system-danger-subtler);
		--snackbar-border-info: var(--border-system-info-boldest);
		--snackbar-border-success: var(--border-system-success-boldest);
		--snackbar-border-warning: var(--border-system-warning-boldest);
		--snackbar-border-error: var(--border-system-danger-bold);
		--button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--button-fill-secondary-pressed: var(--fill-brand-secondary-subtle);
		--list-item-rich-fill-default: var(--fill-neutral-subtlest);
		--list-item-rich-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-rich-fill-selected: var(--fill-brand-secondary-subtle);
		--table-fill-odd: var(--fill-brand-secondary-subtlest);
		--button-fill-secondary-hover: var(--fill-brand-secondary-neutral);
		--tabs-fill: var(--fill-brand-secondary-subtler);
		--tabs-item-fill-primary-selected: var(--fill-brand-primary-boldest);
		--tabs-item-fill-primary-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill: var(--fill-transparent);
		--floating-button-fill-primary-hover: var(--fill-brand-primary-boldest-hover);
		--floating-button-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-border-primary-default: var(--border-brand-secondary-bold);
		--button-fill-primary-destructive: var(--system-dark-red-1000);
		--floating-button-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--floating-button-fill-secondary-pressed: var(--fill-brand-secondary-neutral);
		--list-item-rich-fill-divider: var(--border-brand-secondary-bold);
		--calendar-item-fill: var(--fill-neutral-subtlest);
		--calendar-item-fill-hover: var(--fill-brand-secondary-subtler);
		--calendar-item-fill-selected: var(--fill-brand-primary-boldest);
		--calendar-item-fill-selected-span: var(--fill-brand-primary-subtlest);
		--button-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--list-item-rich-fill-pressed: var(--fill-brand-secondary-neutral);
		--table-fill-pressed: var(--fill-brand-secondary-neutral);
		--tabs-item-fill-secondary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-fill-destructive-hover: var(--system-dark-red-1000);
		--button-border-secondary-destructive: var(--system-dark-red-1000);
		--button-fill-tertiary: var(--fill-neutral-subtlest);
		--button-fill-tertiary-hover: var(--fill-brand-secondary-subtler);
		--button-fill-tertiary-pressed: var(--fill-brand-secondary-neutral);
		--button-fill-tertiary-selected: var(--fill-brand-secondary-subtler);
		--toggle-fill-disabled: var(--fill-disabled);
		--button-fill-primary-destructive-hover: var(--fill-neutral-subtlest);
		--button-text-primary: var(--text-primary-inverted);
		--button-text-primary-inverted: var(--text-primary);
		--button-text-tetriary: var(--text-primary);
		--button-text-primary-destructive: var(--text-primary-inverted);
		--button-text-primary-destructive-inverted: var(--text-error);
		--button-text-brand: var(--text-brand);
		--button-text-disabled: var(--text-disabled);
		--button-icon-primary: var(--icon-primary-inverted);
		--button-icon-primary-inverted: var(--icon-primary);
		--button-icon-brand: var(--icon-brand);
		--button-icon-primary-destructive: var(--icon-primary-inverted);
		--button-icon-disabled: var(--icon-disabled);
		--button-text-secondary: var(--text-secondary);
		--button-icon-secondary: var(--icon-secondary);
		--button-icon-primary-destructive-inverted: var(--icon-error);
		--list-item-rich-border-default: var(--border-neutral-subtle);
		--forms-text-input-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-hover: var(--text-primary);
		--forms-text-input-primary-disabled: var(--text-disabled);
		--forms-text-input-primary-selected: var(--text-primary);
		--forms-text-help-primary-hover: var(--text-primary);
		--forms-text-help-primary-disabled: var(--text-disabled);
		--forms-text-help-primary-selected: var(--text-primary);
		--forms-text-help-primary-enabled: var(--text-secondary);
		--forms-text-input-primary-error: var(--text-primary);
		--forms-text-help-primary-error: var(--text-error);
		--forms-text-input-primary-filled: var(--text-primary);
		--forms-text-help-primary-filled: var(--text-primary);
		--forms-icon-primary-enabled: var(--icon-secondary);
		--forms-icon-primary-hover: var(--icon-primary);
		--forms-icon-primary-disabled: var(--icon-disabled);
		--forms-icon-primary-selected: var(--icon-primary);
		--forms-icon-primary-filled: var(--icon-primary);
		--forms-icon-primary-error: var(--icon-primary);
		--chips-fill-seconadry-default: var(--fill-brand-secondary-subtler);
		--chips-fill-seconadry-selected: var(--fill-neutral-boldest);
		--chips-border-secondary-default: var(--border-brand-secondary-bold);
		--forms-fill-primary-hover: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-selected: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-filled: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-disabled: var(--fill-brand-secondary-subtler);
		--forms-fill-primary-error: var(--fill-brand-secondary-subtler);
		--forms-text-suffix-secondary-enabled: var(--text-secondary);
		--forms-text-suffix-secondary-hover: var(--text-secondary);
		--forms-text-suffix-secondary-selected: var(--text-secondary);
		--forms-text-suffix-secondary-filled: var(--text-secondary);
		--forms-text-suffix-secondary-disabled: var(--text-disabled);
		--forms-text-suffix-secondary-error: var(--text-secondary);
		--forms-border-secondary-error: var(--border-system-danger-boldest);
		--forms-border-secondary-disabled: var(--border-disabled);
		--forms-border-secondary-selected: var(--border-brand-primary-boldest);
		--forms-border-secondary-hover: var(--border-brand-secondary-boldest);
		--forms-border-secondary-filled: rgba(255, 255, 255, 0%);
		--forms-border-secondary-enabled: var(--border-brand-secondary-boldest);
		--forms-fill-secondary-error: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-disabled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-filled: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-selected: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-hover: var(--fill-brand-secondary-subtler);
		--forms-fill-secondary-enabled: var(--fill-brand-secondary-subtler);
		--forms-icon-secondary-error: var(--icon-primary);
		--forms-icon-secondary-hover: var(--icon-primary);
		--forms-icon-secondary-disabled: var(--icon-disabled);
		--forms-icon-secondary-filled: var(--icon-primary);
		--forms-icon-secondary-selected: var(--icon-primary);
		--forms-icon-secondary-enabled: var(--icon-secondary);
		--forms-text-input-secondary-error: var(--text-primary);
		--forms-text-input-secondary-enabled: var(--text-secondary);
		--forms-text-input-secondary-selected: var(--text-primary);
		--forms-text-input-secondary-disabled: var(--text-disabled);
		--forms-text-input-secondary-filled: var(--text-primary);
		--forms-text-input-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-error: var(--text-error);
		--forms-text-help-secondary-selected: var(--text-primary);
		--forms-text-help-secondary-disabled: var(--text-disabled);
		--forms-text-help-secondary-hover: var(--text-primary);
		--forms-text-help-secondary-filled: var(--text-primary);
		--forms-text-help-secondary-enabled: var(--text-secondary);
		--menu-item-fill-destructive-default: rgba(255, 255, 255, 100%);
		--menu-item-fill-destructive-selected: rgba(255, 255, 255, 100%);
		--menu-item-fill-destructive-pressed: rgba(255, 255, 255, 100%);
		--menu-item-fill-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-item-fill-disabled: rgba(255, 255, 255, 100%);
		--menu-item-text-label-primary-default: var(--fill-neutral-subtlest);
		--menu-item-text-label-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-item-text-label-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-text-label-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-item-text-label-destructive-default: rgba(255, 255, 255, 100%);
		--menu-item-text-label-destructive-hover: var(--system-dark-red-1000);
		--menu-item-text-label-destructive-selected: rgba(255, 255, 255, 100%);
		--menu-item-text-label-destructive-pressed: rgba(255, 255, 255, 100%);
		--menu-item-text-label-disabled: rgba(255, 255, 255, 100%);
		--menu-item-icon-label-primary-default: var(--fill-neutral-subtlest);
		--menu-item-icon-label-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-item-icon-label-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-icon-label-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-item-icon-label-destructive-default: rgba(255, 255, 255, 100%);
		--menu-item-icon-label-destructive-hover: var(--system-dark-red-1000);
		--menu-item-icon-label-destructive-selected: rgba(255, 255, 255, 100%);
		--menu-item-icon-label-destructive-pressed: rgba(255, 255, 255, 100%);
		--menu-item-icon-label-disabled: rgba(255, 255, 255, 100%);
		--menu-item-text-shortcut-disabled: rgba(255, 255, 255, 100%);
		--menu-item-text-shortcut-destructive-pressed: rgba(255, 255, 255, 100%);
		--menu-item-text-shortcut-destructive-selected: rgba(255, 255, 255, 100%);
		--menu-item-text-shortcut-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-item-text-shortcut-destructive-default: rgba(255, 255, 255, 100%);
		--menu-item-text-shortcut-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-text-shortcut-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-item-text-shortcut-destructive-hover: var(--system-dark-red-1000);
		--menu-item-text-shortcut-primary-default: var(--fill-neutral-subtlest);
		--menu-item-icon-shortcut-destructive-pressed: rgba(255, 255, 255, 100%);
		--menu-item-icon-shortcut-destructive-hover: var(--system-dark-red-1000);
		--menu-item-icon-shortcut-destructive-default: rgba(255, 255, 255, 100%);
		--menu-item-icon-shortcut-disabled: rgba(255, 255, 255, 100%);
		--menu-item-icon-shortcut-primary-pressed: var(--fill-brand-primary-boldest);
		--menu-item-icon-shortcut-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-item-icon-shortcut-destructive-selected: rgba(255, 255, 255, 100%);
		--menu-item-icon-shortcut-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-item-icon-shortcut-primary-default: var(--fill-neutral-subtlest);
		--menu-item-text-input-selected: var(--text-primary);
		--menu-item-text-input-hover: var(--text-primary);
		--menu-item-text-input-default: var(--text-secondary);
		--menu-item-icon-input-selected: var(--text-primary);
		--menu-item-icon-input-hover: var(--text-primary);
		--menu-item-icon-input-default: var(--text-secondary);
		--menu-search-fill-primary-default: var(--fill-neutral-subtlest);
		--menu-search-fill-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-search-fill-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-search-fill-primary-filled: var(--fill-brand-primary-boldest);
		--menu-search-fill-disabled: rgba(255, 255, 255, 100%);
		--menu-search-text-primary-default: var(--fill-neutral-subtlest);
		--menu-search-text-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-search-text-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-search-text-primary-filled: var(--fill-brand-primary-boldest);
		--menu-search-text-disabled: rgba(255, 255, 255, 100%);
		--menu-search-icon-primary-default: var(--fill-neutral-subtlest);
		--menu-search-icon-primary-hover: var(--fill-brand-secondary-subtle);
		--menu-search-icon-primary-selected: var(--fill-brand-secondary-subtle);
		--menu-search-icon-primary-filled: var(--fill-brand-primary-boldest);
		--menu-search-icon-disabled: rgba(255, 255, 255, 100%);
		--menu-border-primary-selected: var(--border-brand-primary-boldest);
		--divider-fill-primary-default: rgba(255, 255, 255, 100%);
		--scroll-fill-track-primary-enabled: rgba(255, 255, 255, 100%);
		--scroll-fill-thumb-primary-enabled: rgba(255, 255, 255, 100%);
		--scroll-fill-thumb-primary-hover: rgba(255, 255, 255, 100%);
		--scroll-fill-thumb-primary-pressed: rgba(255, 255, 255, 100%);
		--scroll-fill-track-primary-pressed: rgba(255, 255, 255, 100%);
		--scroll-fill-track-primary-hover: rgba(255, 255, 255, 100%);
		--scroll-fill-track-primary-disabled: rgba(255, 255, 255, 100%);
		--scroll-fill-thumb-primary-disabled: rgba(255, 255, 255, 100%);
		--menu-fill-primary-default: rgba(255, 255, 255, 100%);
		--dialog-fill-primary-default: rgba(255, 255, 255, 100%);
		--dialog-text-headline: rgba(255, 255, 255, 100%);
		--dialog-border-primary-default: rgba(255, 255, 255, 100%);
		--dialog-icon-primary-default: rgba(255, 255, 255, 100%);
		--list-item-rich-text-headline: rgba(255, 255, 255, 100%);
		--accordion-rich-border-default: var(--border-neutral-subtle);
		--accordion-rich-fill-default: var(--fill-neutral-subtlest);
		--accordion-rich-fill-hover: var(--fill-brand-secondary-subtler);
		--accordion-rich-fill-selected: var(--fill-brand-secondary-subtle);
		--accordion-rich-fill-divider: var(--border-brand-secondary-bold);
		--accordion-rich-text-headline: rgba(255, 255, 255, 100%);
		--accordion-rich-text-subheadline: rgba(255, 255, 255, 100%);
		--accordion-simple-text-headline: rgba(255, 255, 255, 100%);
		--accordion-simple-text-body: rgba(255, 255, 255, 100%);
		--accordion-rich-text-body: rgba(255, 255, 255, 100%);
		--list-item-simple-fill-default: var(--fill-neutral-subtlest);
		--list-item-simple-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-simple-fill-selected: var(--fill-brand-secondary-subtle);
		--list-item-simple-fill-pressed: var(--fill-brand-secondary-neutral);
		--list-item-simple-text-default: rgba(255, 255, 255, 100%);
		--list-item-simple-text-hover: rgba(255, 255, 255, 100%);
		--list-item-simple-text-selected: rgba(255, 255, 255, 100%);
		--list-item-simple-text-pressed: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-default: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-hover: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-selected: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-pressed: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-right-pressed: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-pressed-2: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-right-selected: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-selected-2: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-right-hover: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-left-hover-2: rgba(255, 255, 255, 100%);
		--list-item-simple-icon-right-default: rgba(255, 255, 255, 100%);
		--grid-item-fill-default: var(--fill-neutral-subtlest);
		--grid-item-fill-hover: var(--fill-brand-secondary-subtler);
		--list-item-simple-icon-left-default-2: rgba(255, 255, 255, 100%);
		--grid-item-header-icon-default: rgba(255, 255, 255, 100%);
		--grid-item-header-icon-hover: rgba(255, 255, 255, 100%);
		--label-text-neutral: rgba(255, 255, 255, 100%);
		--label-text-brand: rgba(255, 255, 255, 100%);
		--label-text-secondary: rgba(255, 255, 255, 100%);
		--label-text-disabled: rgba(255, 255, 255, 100%);
		--label-text-inverted: rgba(255, 255, 255, 100%);
		--label-text-destructive: rgba(255, 255, 255, 100%);
		--label-icon-primary: rgba(255, 255, 255, 100%);
		--label-icon-brand: rgba(255, 255, 255, 100%);
		--label-icon-secondary: rgba(255, 255, 255, 100%);
		--label-icon-disabled: rgba(255, 255, 255, 100%);
		--label-icon-inverted: rgba(255, 255, 255, 100%);
		--label-icon-destructive: rgba(255, 255, 255, 100%);
		--snackbar-text-info: var(--border-system-info-boldest);
		--snackbar-text-success: var(--border-system-success-boldest);
		--snackbar-text-warning: var(--border-system-warning-boldest);
		--snackbar-text-error: var(--border-system-danger-bold);
		--banner-fill-info: var(--fill-system-info-subtler);
		--banner-fill-warning: var(--fill-system-warning-subtler);
		--banner-fill-success: var(--fill-system-success-subtler);
		--banner-fill-error: var(--fill-system-danger-subtler);
		--banner-text-info: var(--border-system-info-boldest);
		--banner-text-success: var(--border-system-success-boldest);
		--banner-text-warning: var(--border-system-warning-boldest);
		--banner-text-error: var(--border-system-danger-bold);
		--breadcrumb-fill-primary-default: var(--fill-system-info-subtler);
		--breadcrumb-fill-primary-hover: var(--fill-system-warning-subtler);
		--breadcrumb-text-primary-default: var(--border-system-info-boldest);
		--breadcrumb-text-primary-hover: var(--border-system-success-boldest);
		--breadcrumb-text-primary-last: var(--border-system-success-boldest);
		--breadcrumb-icon-chevron-primary-default: var(--fill-system-info-subtler);
		--breadcrumb-icon-chevron-primary-hover: var(--fill-system-warning-subtler);
		--main-navigation-item-fill-default: var(--fill-system-info-subtler);
		--main-navigation-item-fill-hover: var(--fill-system-warning-subtler);
		--main-navigation-item-fill-active: var(--fill-system-warning-subtler);
		--main-navigation-item-text-default: var(--border-system-info-boldest);
		--main-navigation-item-text-hover: var(--border-system-success-boldest);
		--main-navigation-item-text-active: var(--border-system-success-boldest);
		--main-navigation-item-icon-default: var(--fill-system-info-subtler);
		--main-navigation-item-icon-hover: var(--fill-system-warning-subtler);
		--main-navigation-item-icon-active: var(--fill-system-warning-subtler);
		--main-navigation-fill-default: rgba(255, 255, 255, 100%);
		--table-fill-default: var(--fill-neutral-subtlest);
		--breadcrumb-fill-secondary-hover: var(--fill-system-warning-subtler);
		--breadcrumb-fill-secondary-default: var(--fill-system-info-subtler);
		--breadcrumb-text-secondary-hover: var(--border-system-success-boldest);
		--breadcrumb-text-secondary-default: var(--border-system-info-boldest);
		--breadcrumb-text-secondary-last: var(--border-system-success-boldest);
		--breadcrumb-icon-chevron-secondary-hover: var(--fill-system-warning-subtler);
		--breadcrumb-icon-chevron-secondary-default: var(--fill-system-info-subtler);
		--breadcrumb-icon-chevron-primary-last: var(--fill-system-warning-subtler);
		--breadcrumb-icon-chevron-secondary-last: var(--fill-system-warning-subtler);
		--breadcrumb-icon-left-secondary-last: var(--fill-system-warning-subtler);
		--breadcrumb-icon-left-primary-hover: var(--fill-system-warning-subtler);
		--breadcrumb-icon-left-secondary-hover: var(--fill-system-warning-subtler);
		--breadcrumb-icon-left-secondary-default: var(--fill-system-info-subtler);
		--breadcrumb-icon-left-primary-last: var(--fill-system-warning-subtler);
		--breadcrumb-icon-left-primary-default: var(--fill-system-info-subtler);
		--sidebar-fill-default: rgba(255, 255, 255, 100%);
		--sidebar-border-default: rgba(255, 255, 255, 100%);
		--tabs-fill-primary: var(--fill-brand-secondary-subtle);
		--tabs-fill-secondary: var(--fill-brand-secondary-subtle);
		--tabs-item-fill-primary-enabled: var(--fill-brand-secondary-subtle);
		--tabs-item-fill-secondary-enabled: var(--fill-brand-secondary-subtle);
		--tabs-item-fill-secondary-hover: var(--fill-brand-secondary-subtle);
		--tabs-item-icon-primary-enabled: var(--fill-brand-secondary-subtle);
		--tabs-item-icon-primary-selected: var(--fill-brand-primary-boldest);
		--tabs-item-icon-primary-hover: var(--fill-brand-secondary-subtle);
		--tabs-item-icon-secondary-enabled: var(--fill-brand-secondary-subtle);
		--tabs-item-icon-secondary-selected: var(--fill-brand-secondary-subtle);
		--tabs-item-icon-secondary-hover: var(--fill-brand-secondary-subtle);
		--tabs-border-primary: var(--fill-brand-secondary-subtle);
		--tabs-border-secondary: var(--fill-brand-secondary-subtle);
		--paginator-fill-default: rgba(255, 255, 255, 100%);
		--paginator-text-primary: rgba(255, 255, 255, 100%);
		--chips-fill-primary-default: var(--fill-brand-secondary-subtler);
		--chips-fill-primary-selected: var(--fill-neutral-boldest);
		--chips-border-primary-default: var(--border-brand-secondary-bold);
		--chips-border-secondary-selected: var(--border-brand-secondary-bold);
		--chips-border-primary-selected: var(--border-brand-secondary-bold);
		--loader-fill-primary-light: rgba(255, 255, 255, 100%);
		--loader-fill-primary-strong: rgba(255, 255, 255, 100%);
		--loader-fill-inverted-strong: rgba(255, 255, 255, 100%);
		--loader-fill-inverted-light: rgba(255, 255, 255, 100%);
		--skeleton-fill-default: rgba(255, 255, 255, 100%);
		--tooltip-fill-default: rgba(255, 255, 255, 100%);
		--tooltip-text-default: rgba(255, 255, 255, 100%);
		--pill-border-info: var(--border-system-info-boldest);
		--pill-border-success: var(--border-system-success-boldest);
		--pill-border-warning: var(--border-system-warning-boldest);
		--pill-border-error: var(--border-system-danger-bold);
		--pill-fill-info: var(--fill-system-info-subtler);
		--pill-fill-warning: var(--fill-system-warning-subtler);
		--pill-fill-success: var(--fill-system-success-subtler);
		--pill-fill-error: var(--fill-system-danger-subtler);
		--pill-text-info: var(--border-system-info-boldest);
		--pill-text-success: var(--border-system-success-boldest);
		--pill-text-warning: var(--border-system-warning-boldest);
		--pill-text-error: var(--border-system-danger-bold);
		--toggle-fill-handle: rgba(255, 255, 255, 100%);
		--stepper-item-icon-fill-done: var(--fill-system-info-subtler);
		--stepper-item-icon-fill-waiting: var(--fill-system-warning-subtler);
		--stepper-item-icon-fill-active: var(--fill-system-warning-subtler);
		--stepper-item-icon-text-done: var(--border-system-info-boldest);
		--stepper-item-icon-text-waiting: var(--border-system-success-boldest);
		--stepper-item-icon-text-active: var(--border-system-success-boldest);
		--stepper-item-icon-icon-done: var(--fill-system-info-subtler);
		--stepper-item-icon-border-active: var(--fill-system-info-subtler);
		--button-group-fill-default: var(--fill-system-warning-subtler);
		--button-group-item-text-enabled: var(--border-system-success-boldest);
		--button-group-item-text-selected: var(--border-system-success-boldest);
		--button-group-item-text-disabled: var(--border-system-info-boldest);
		--button-group-item-icon-enabled: var(--fill-system-info-subtler);
		--active: var(--fill-system-warning-subtler);
		--waiting: var(--fill-system-warning-subtler);
		--done: var(--fill-system-info-subtler);
		--button-group-border-default: var(--fill-system-info-subtler);
		--button-group-item-icon-selected: var(--fill-system-info-subtler);
		--button-group-item-icon-disabled: var(--fill-system-info-subtler);
		--button-group-item-fill-enabled: var(--fill-system-warning-subtler);
		--button-group-item-fill-selected: var(--fill-system-warning-subtler);
		--button-group-item-fill-disabled: var(--fill-system-info-subtler);
		--checkbox-item-icon-default: var(--fill-neutral-bold);
		--checkbox-item-icon-disabled: var(--fill-disabled);
		--checkbox-item-icon-hover: var(--fill-neutral-boldest);
		--checkbox-item-checkbox-icon-checked: var(--fill-neutral-boldest);
		--checkbox-item-checkbox-icon-hover: var(--fill-neutral-boldest);
		--checkbox-item-checkbox-icon-disabled: var(--fill-disabled);
		--checkbox-item-checkbox-fill-hover: var(--fill-neutral-boldest);
		--checkbox-item-checkbox-fill-checked: var(--fill-neutral-boldest);
		--checkbox-item-checkbox-fill-disabled: var(--fill-disabled);
	}

	[data-variant="xs"], &[data-variant="xs"] {
		--button-space-padding-horisontal: var(--space-200);
		--button-radius: var(--border-radius-xs);
		--forms-radius: var(--border-radius-xs);
		--button-height: var(--height-xs);
		--button-space-gap: var(--space-200);
		--forms-height: var(--height-xs);
		--forms-space-padding-horisontal: var(--space-100);
		--forms-space-gap: var(--space-050);
		--tabs-height: var(--height-xs);
		--tabs-space-padding-horisontal: 0px;
		--tabs-radius: var(--border-radius-xs);
		--table-space-padding-horisontal: var(--space-300);
		--snackbar-radius: 0px;
		--snackbar-space-padding-horisontal: 0px;
		--snackbar-space-gap: 0px;
		--snackbar-space-padding-vertical: 0px;
		--banner-radius: 0px;
		--banner-space-padding-horisontal: 0px;
		--banner-space-padding-vertical: 0px;
		--label-space-gap: var(--space-100);
		--button-space-padding-focused: var(--border-width-sm);
		--menu-radius: var(--border-radius-xs);
		--menu-space-padding-horizontal: var(--space-050);
		--menu-min-width: var(--min-width-md);
		--divider-space-padding-vertical: 0px;
		--scroll-radius: var(--border-radius-full);
		--scroll-width: var(--space-200);
		--scroll-space-padding-horisontal: var(--space-050);
		--scroll-space-padding-vertical: var(--space-050);
		--menu-item-space-padding-horizontal: var(--space-200);
		--menu-space-padding-vertical: var(--space-050);
		--dialog-radius: var(--border-radius-md);
		--dialog-space-padding-horisontal: var(--space-400);
		--dialog-space-padding-vertical: var(--space-400);
		--dialog-space-gap: var(--space-400);
		--dialog-border: var(--border-width-sm);
		--dialog-space-gap-content: 0px;
		--dialog-space-gap-main-actions: var(--space-300);
		--dialog-width: 400px;
		--checkbox-list-space-gap: 0px;
		--list-item-radius: 0px;
		--list-item-border: 0px;
		--list-item-rich-space-with-background-padding-horizontal: 0px;
		--list-item-rich-space-with-background-padding-vertical: 0px;
		--list-item-rich-space-gap-left: 0px;
		--list-item-rich-space-gap-actions: 0px;
		--list-item-rich-space-with-background-gap-left-text: 0px;
		--list-item-rich-space-no-background-padding-horizontal: 0px;
		--list-item-rich-space-no-background-padding-vertical: 0px;
		--list-item-rich-space-no-background-gap-left-text: 0px;
		--list-item-simple-space-padding-horizontal: 0px;
		--list-item-simple-space-padding-vertical: 0px;
		--list-item-simple-space-gap: 0px;
		--list-item-simple-height: 0px;
		--list-item-simple-radius: 0px;
		--grid-item-header-space-gap: 0px;
		--grid-item-radius: var(--border-radius-sm);
		--grid-item-space-gap: var(--space-100);
		--grid-item-space-padding-horizontal: var(--space-100);
		--grid-item-space-padding-vertical: var(--space-100);
		--grid-list-space-gap: var(--space-100);
		--accordion-rich-space-padding-horizontal: 0px;
		--accordion-rich-space-padding-vertical: 0px;
		--accordion-rich-space-gap-left-text: 0px;
		--accordion-radius: 0px;
		--accordion-rich-space-gap-left: 0px;
		--accordion-border: 0px;
		--accordion-rich-space-gap-actions: 0px;
		--accordion-simple-space-gap: 0px;
		--accordion-rich-radius: 0px;
		--accordion-rich-space-padding-bottom-head: 0px;
		--accordion-rich-space-padding-top-body: 0px;
		--accordion-simple-space-gap-actions: 0px;
		--menu-item-space-gap-option: var(--space-100);
		--menu-item-space-gap-shortcut: var(--space-100);
		--icon-width: var(--numeric-300);
		--icon-height: var(--numeric-300);
		--icon-font-icon-size: var(--numeric-400);
		--breadcrumbs-item-radius-crumb: 0px;
		--breadcrumbs-item-space-padding-horisontal-crumb: 0px;
		--breadcrumbs-item-space-padding-vertical-crumb: 0px;
		--breadcrumbs-item-space-gap: 0px;
		--breadcrumbs-space-gap: 0px;
		--main-navigation-space-gap-top: 0px;
		--main-navigation-item-space-padding-horisontal-expanded: 0px;
		--main-navigation-item-space-padding-vertical-expanded: 0px;
		--main-navigation-item-radius: 0px;
		--main-navigation-item-height: 0px;
		--main-navigation-item-space-padding-horisontal-collapsed: 0px;
		--main-navigation-item-space-padding-vertical-collapsed: 0px;
		--main-navigation-space-padding-vertical: 0px;
		--main-navigation-space-padding-horisontal: 0px;
		--table-row-space-gap: var(--space-300);
		--table-row-height: var(--height-sm);
		--table-item-height: var(--height-sm);
		--table-space-padding-vertical: var(--space-300);
		--header-space-padding-horisontal: 0px;
		--header-space-padding-vertical: 0px;
		--header-space-gap-breadcrumbs: 0px;
		--header-space-gap-dropdown: 0px;
		--header-space-gap-right: 0px;
		--header-space-gap-actions: 0px;
		--sidebar-space-padding-horisontal: var(--space-200);
		--sidebar-space-padding-vertical: var(--space-200);
		--sidebar-radius: var(--border-radius-md);
		--sidebar-border: var(--border-width-sm);
		--tabs-item-space-padding-horisontal-text: 0px;
		--tabs-item-radius: 0px;
		--tabs-item-height: 0px;
		--tabs-item-space-padding-horisontal-icon: 0px;
		--paginator-space-padding-vertical: 0px;
		--paginator-space-gap-show: 0px;
		--paginator-space-gap-page: 0px;
		--chips-radius: 0px;
		--chips-border: 0px;
		--chips-space-padding-horisontal: 0px;
		--chips-space-padding-vertical: 0px;
		--chips-height: 0px;
		--snackbar-border: 0px;
		--tooltip-radius: 0px;
		--tooltip-space-padding-horisontal: 0px;
		--tooltip-space-padding-vertical: 0px;
		--pill-radius: 0px;
		--pill-space-padding-horisontal: 0px;
		--pill-space-padding-vertical: 0px;
		--toggle-item-radius: 0px;
		--toggle-item-space-padding-horisontal: 0px;
		--toggle-item-space-padding-vertical: 0px;
		--toggle-space-gap: 0px;
		--stepper-item-space-gap: 0px;
		--stepper-space-gap: 0px;
		--stepper-item-icon-radius: 0px;
		--stepper-item-icon-height: 0px;
		--stepper-item-icon-width: 0px;
		--stepper-item-icon-border: 0px;
		--radio-button-list-space-gap: 0px;
		--button-group-item-space-padding-horisontal-icon: var(--numeric-100);
		--button-group-border: var(--border-width-xs);
		--button-group-item-space-padding-horisontal-text: var(--numeric-200);
		--button-group-item-height: var(--height-xs);
		--button-group-item-radius: var(--border-radius-xs);
		--button-group-radius: var(--border-radius-xs);
		--checkbox-space-gap: 0px;
		--list-item-rich-space-gap: 0px;
		--list-item-rich-space-secondary-boldest: var(--brand-metal-1000);
		--list-item-rich-space-sm: var(--numeric-100);
	}

	[data-variant="sm"], &[data-variant="sm"] {
		--button-space-padding-horisontal: var(--space-200);
		--button-radius: var(--border-radius-sm);
		--forms-radius: var(--border-radius-sm);
		--button-height: var(--height-sm);
		--button-space-gap: var(--space-100);
		--forms-height: var(--height-sm);
		--forms-space-padding-horisontal: var(--space-200);
		--forms-space-gap: var(--space-200);
		--tabs-height: var(--height-sm);
		--tabs-space-padding-horisontal: var(--space-300);
		--tabs-radius: var(--border-radius-sm);
		--table-space-padding-horisontal: var(--space-400);
		--snackbar-radius: 0px;
		--snackbar-space-padding-horisontal: 0px;
		--snackbar-space-gap: 0px;
		--snackbar-space-padding-vertical: 0px;
		--banner-radius: 0px;
		--banner-space-padding-horisontal: 0px;
		--banner-space-padding-vertical: 0px;
		--label-space-gap: var(--space-100);
		--button-space-padding-focused: var(--border-width-sm);
		--menu-radius: var(--border-radius-sm);
		--menu-space-padding-horizontal: var(--space-100);
		--menu-min-width: var(--min-width-lg);
		--divider-space-padding-vertical: var(--space-050);
		--scroll-radius: var(--border-radius-full);
		--scroll-width: var(--space-200);
		--scroll-space-padding-horisontal: var(--space-050);
		--scroll-space-padding-vertical: var(--space-050);
		--menu-item-space-padding-horizontal: var(--space-200);
		--menu-space-padding-vertical: var(--space-100);
		--dialog-radius: var(--border-radius-md);
		--dialog-space-padding-horisontal: var(--space-600);
		--dialog-space-padding-vertical: var(--space-600);
		--dialog-space-gap: var(--space-600);
		--dialog-border: var(--border-width-sm);
		--dialog-space-gap-content: var(--space-300);
		--dialog-space-gap-main-actions: var(--space-400);
		--dialog-width: 500px;
		--checkbox-list-space-gap: var(--space-200);
		--list-item-radius: 0px;
		--list-item-border: 0px;
		--list-item-rich-space-with-background-padding-horizontal: 0px;
		--list-item-rich-space-with-background-padding-vertical: 0px;
		--list-item-rich-space-gap-left: 0px;
		--list-item-rich-space-gap-actions: 0px;
		--list-item-rich-space-with-background-gap-left-text: 0px;
		--list-item-rich-space-no-background-padding-horizontal: 0px;
		--list-item-rich-space-no-background-padding-vertical: 0px;
		--list-item-rich-space-no-background-gap-left-text: 0px;
		--list-item-simple-space-padding-horizontal: var(--space-200);
		--list-item-simple-space-padding-vertical: var(--space-100);
		--list-item-simple-space-gap: var(--space-100);
		--list-item-simple-height: var(--height-xs);
		--list-item-simple-radius: var(--border-radius-xs);
		--grid-item-header-space-gap: var(--space-200);
		--grid-item-radius: var(--border-radius-sm);
		--grid-item-space-gap: var(--space-100);
		--grid-item-space-padding-horizontal: var(--space-100);
		--grid-item-space-padding-vertical: var(--space-100);
		--grid-list-space-gap: var(--space-200);
		--accordion-rich-space-padding-horizontal: 0px;
		--accordion-rich-space-padding-vertical: 0px;
		--accordion-rich-space-gap-left-text: 0px;
		--accordion-radius: 0px;
		--accordion-rich-space-gap-left: 0px;
		--accordion-border: 0px;
		--accordion-rich-space-gap-actions: 0px;
		--accordion-simple-space-gap: var(--space-100);
		--accordion-rich-radius: 0px;
		--accordion-rich-space-padding-bottom-head: 0px;
		--accordion-rich-space-padding-top-body: 0px;
		--accordion-simple-space-gap-actions: var(--space-200);
		--menu-item-space-gap-option: var(--space-100);
		--menu-item-space-gap-shortcut: var(--space-100);
		--icon-width: var(--numeric-500);
		--icon-height: var(--numeric-500);
		--icon-font-icon-size: var(--numeric-500);
		--breadcrumbs-item-radius-crumb: var(--border-radius-full);
		--breadcrumbs-item-space-padding-horisontal-crumb: var(--space-200);
		--breadcrumbs-item-space-padding-vertical-crumb: var(--space-100);
		--breadcrumbs-item-space-gap: var(--space-100);
		--breadcrumbs-space-gap: var(--space-100);
		--main-navigation-space-gap-top: 0px;
		--main-navigation-item-space-padding-horisontal-expanded: 0px;
		--main-navigation-item-space-padding-vertical-expanded: 0px;
		--main-navigation-item-radius: 0px;
		--main-navigation-item-height: 0px;
		--main-navigation-item-space-padding-horisontal-collapsed: 0px;
		--main-navigation-item-space-padding-vertical-collapsed: 0px;
		--main-navigation-space-padding-vertical: 0px;
		--main-navigation-space-padding-horisontal: 0px;
		--table-row-space-gap: var(--space-400);
		--table-row-height: var(--height-sm);
		--table-item-height: var(--height-sm);
		--table-space-padding-vertical: var(--space-400);
		--header-space-padding-horisontal: 0px;
		--header-space-padding-vertical: 0px;
		--header-space-gap-breadcrumbs: 0px;
		--header-space-gap-dropdown: 0px;
		--header-space-gap-right: 0px;
		--header-space-gap-actions: 0px;
		--sidebar-space-padding-horisontal: var(--space-300);
		--sidebar-space-padding-vertical: var(--space-300);
		--sidebar-radius: var(--border-radius-md);
		--sidebar-border: var(--border-width-sm);
		--tabs-item-space-padding-horisontal-text: var(--space-300);
		--tabs-item-radius: var(--border-radius-sm);
		--tabs-item-height: var(--height-sm);
		--tabs-item-space-padding-horisontal-icon: var(--space-200);
		--paginator-space-padding-vertical: 0px;
		--paginator-space-gap-show: 0px;
		--paginator-space-gap-page: 0px;
		--chips-radius: 0px;
		--chips-border: 0px;
		--chips-space-padding-horisontal: 0px;
		--chips-space-padding-vertical: 0px;
		--chips-height: 0px;
		--snackbar-border: 0px;
		--tooltip-radius: 0px;
		--tooltip-space-padding-horisontal: 0px;
		--tooltip-space-padding-vertical: 0px;
		--pill-radius: 0px;
		--pill-space-padding-horisontal: 0px;
		--pill-space-padding-vertical: 0px;
		--toggle-item-radius: var(--border-radius-full);
		--toggle-item-space-padding-horisontal: var(--space-050);
		--toggle-item-space-padding-vertical: var(--space-050);
		--toggle-space-gap: var(--space-200);
		--stepper-item-space-gap: var(--space-200);
		--stepper-space-gap: var(--space-200);
		--stepper-item-icon-radius: var(--border-radius-full);
		--stepper-item-icon-height: var(--height-xs);
		--stepper-item-icon-width: var(--height-xs);
		--stepper-item-icon-border: var(--border-width-sm);
		--radio-button-list-space-gap: var(--space-200);
		--button-group-item-space-padding-horisontal-icon: var(--numeric-200);
		--button-group-border: var(--border-width-sm);
		--button-group-item-space-padding-horisontal-text: var(--numeric-400);
		--button-group-item-height: var(--height-sm);
		--button-group-item-radius: var(--border-radius-sm);
		--button-group-radius: var(--border-radius-sm);
		--checkbox-space-gap: 0px;
		--list-item-rich-space-gap: 0px;
		--list-item-rich-space-secondary-boldest: var(--brand-dark-grey-1000);
		--list-item-rich-space-sm: var(--numeric-100);
	}

	[data-variant="md"], &[data-variant="md"] {
		--button-space-padding-horisontal: var(--space-400);
		--button-radius: var(--border-radius-md);
		--forms-radius: var(--border-radius-sm);
		--button-height: var(--height-md);
		--button-space-gap: var(--space-200);
		--forms-height: var(--height-md);
		--forms-space-padding-horisontal: var(--space-300);
		--forms-space-gap: var(--space-200);
		--tabs-height: var(--height-md);
		--tabs-space-padding-horisontal: var(--space-400);
		--tabs-radius: var(--border-radius-md);
		--table-space-padding-horisontal: var(--space-400);
		--snackbar-radius: var(--border-radius-md);
		--snackbar-space-padding-horisontal: var(--space-300);
		--snackbar-space-gap: var(--space-200);
		--snackbar-space-padding-vertical: var(--space-200);
		--banner-radius: var(--border-radius-md);
		--banner-space-padding-horisontal: var(--space-200);
		--banner-space-padding-vertical: var(--space-200);
		--label-space-gap: var(--space-200);
		--button-space-padding-focused: var(--border-width-sm);
		--menu-radius: var(--border-radius-sm);
		--menu-space-padding-horizontal: var(--space-100);
		--menu-min-width: var(--min-width-lg);
		--divider-space-padding-vertical: var(--space-100);
		--scroll-radius: var(--border-radius-full);
		--scroll-width: var(--space-200);
		--scroll-space-padding-horisontal: var(--space-050);
		--scroll-space-padding-vertical: var(--space-050);
		--menu-item-space-padding-horizontal: var(--space-300);
		--menu-space-padding-vertical: var(--space-100);
		--dialog-radius: var(--border-radius-lg);
		--dialog-space-padding-horisontal: var(--space-800);
		--dialog-space-padding-vertical: var(--space-800);
		--dialog-space-gap: var(--space-800);
		--dialog-border: var(--border-width-sm);
		--dialog-space-gap-content: var(--space-400);
		--dialog-space-gap-main-actions: var(--space-400);
		--dialog-width: 600px;
		--checkbox-list-space-gap: 0px;
		--list-item-radius: var(--border-radius-sm);
		--list-item-border: var(--border-width-sm);
		--list-item-rich-space-with-background-padding-horizontal: var(--space-400);
		--list-item-rich-space-with-background-padding-vertical: var(--space-400);
		--list-item-rich-space-gap-left: var(--space-300);
		--list-item-rich-space-gap-actions: var(--space-100);
		--list-item-rich-space-with-background-gap-left-text: var(--space-100);
		--list-item-rich-space-no-background-padding-horizontal: var(--space-200);
		--list-item-rich-space-no-background-padding-vertical: var(--space-200);
		--list-item-rich-space-no-background-gap-left-text: var(--space-050);
		--list-item-simple-space-padding-horizontal: 0px;
		--list-item-simple-space-padding-vertical: 0px;
		--list-item-simple-space-gap: 0px;
		--list-item-simple-height: 0px;
		--list-item-simple-radius: 0px;
		--grid-item-header-space-gap: 0px;
		--grid-item-radius: 0px;
		--grid-item-space-gap: 0px;
		--grid-item-space-padding-horizontal: 0px;
		--grid-item-space-padding-vertical: 0px;
		--grid-list-space-gap: 0px;
		--accordion-rich-space-padding-horizontal: var(--space-400);
		--accordion-rich-space-padding-vertical: var(--space-400);
		--accordion-rich-space-gap-left-text: var(--space-050);
		--accordion-radius: var(--border-radius-sm);
		--accordion-rich-space-gap-left: var(--space-200);
		--accordion-border: var(--border-width-sm);
		--accordion-rich-space-gap-actions: var(--space-300);
		--accordion-simple-space-gap: 0px;
		--accordion-rich-radius: var(--border-radius-md);
		--accordion-rich-space-padding-bottom-head: var(--space-400);
		--accordion-rich-space-padding-top-body: var(--space-400);
		--accordion-simple-space-gap-actions: 0px;
		--menu-item-space-gap-option: var(--space-200);
		--menu-item-space-gap-shortcut: var(--space-200);
		--icon-width: var(--numeric-600);
		--icon-height: var(--numeric-600);
		--icon-font-icon-size: var(--numeric-600);
		--breadcrumbs-item-radius-crumb: var(--border-radius-full);
		--breadcrumbs-item-space-padding-horisontal-crumb: var(--space-300);
		--breadcrumbs-item-space-padding-vertical-crumb: var(--space-200);
		--breadcrumbs-item-space-gap: var(--space-100);
		--breadcrumbs-space-gap: var(--space-100);
		--main-navigation-space-gap-top: var(--space-400);
		--main-navigation-item-space-padding-horisontal-expanded: var(--space-300);
		--main-navigation-item-space-padding-vertical-expanded: var(--space-200);
		--main-navigation-item-radius: var(--border-radius-md);
		--main-navigation-item-height: var(--height-md);
		--main-navigation-item-space-padding-horisontal-collapsed: var(--space-200);
		--main-navigation-item-space-padding-vertical-collapsed: var(--space-200);
		--main-navigation-space-padding-vertical: var(--space-500);
		--main-navigation-space-padding-horisontal: var(--space-500);
		--table-row-space-gap: var(--space-400);
		--table-row-height: var(--height-md);
		--table-item-height: var(--height-md);
		--table-space-padding-vertical: var(--space-400);
		--header-space-padding-horisontal: var(--space-500);
		--header-space-padding-vertical: var(--space-300);
		--header-space-gap-breadcrumbs: var(--space-400);
		--header-space-gap-dropdown: var(--space-300);
		--header-space-gap-right: var(--space-400);
		--header-space-gap-actions: var(--space-300);
		--sidebar-space-padding-horisontal: var(--space-400);
		--sidebar-space-padding-vertical: var(--space-400);
		--sidebar-radius: var(--border-radius-md);
		--sidebar-border: var(--border-width-sm);
		--tabs-item-space-padding-horisontal-text: var(--space-400);
		--tabs-item-radius: var(--border-radius-md);
		--tabs-item-height: var(--height-md);
		--tabs-item-space-padding-horisontal-icon: var(--space-200);
		--paginator-space-padding-vertical: var(--space-400);
		--paginator-space-gap-show: var(--space-300);
		--paginator-space-gap-page: var(--space-300);
		--chips-radius: var(--border-radius-xs);
		--chips-border: var(--border-width-xs);
		--chips-space-padding-horisontal: var(--space-200);
		--chips-space-padding-vertical: var(--space-050);
		--chips-height: var(--height-xs);
		--snackbar-border: var(--border-width-sm);
		--tooltip-radius: var(--border-radius-xs);
		--tooltip-space-padding-horisontal: var(--space-200);
		--tooltip-space-padding-vertical: var(--space-100);
		--pill-radius: var(--border-radius-xs);
		--pill-space-padding-horisontal: var(--space-100);
		--pill-space-padding-vertical: var(--space-050);
		--toggle-item-radius: var(--border-radius-full);
		--toggle-item-space-padding-horisontal: var(--space-050);
		--toggle-item-space-padding-vertical: var(--space-050);
		--toggle-space-gap: var(--space-200);
		--stepper-item-space-gap: var(--space-200);
		--stepper-space-gap: var(--space-200);
		--stepper-item-icon-radius: var(--border-radius-full);
		--stepper-item-icon-height: var(--height-sm);
		--stepper-item-icon-width: var(--height-sm);
		--stepper-item-icon-border: var(--border-width-sm);
		--radio-button-list-space-gap: 0px;
		--button-group-item-space-padding-horisontal-icon: var(--numeric-300);
		--button-group-border: var(--border-width-sm);
		--button-group-item-space-padding-horisontal-text: var(--numeric-400);
		--button-group-item-height: var(--height-md);
		--button-group-item-radius: var(--border-radius-sm);
		--button-group-radius: var(--border-radius-sm);
		--checkbox-space-gap: var(--space-200);
		--list-item-rich-space-gap: var(--space-200);
		--list-item-rich-space-secondary-boldest: var(--brand-dark-grey-1000);
		--list-item-rich-space-sm: var(--numeric-100);
	}
}