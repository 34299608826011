@use '../../../style/mixins';
@use '../../mixins/scrollbar';

:where(:root:not([data-uinew])) [ui-theme],
:where(:root:not([data-uinew])) body [ui-theme='default'],
:where(:root:not([data-uinew])) {
    // Heights

    /**
    * @deprecated OLD VARIABLES
    */
    --default-height: 4rem;
    --default-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 5%);
    --default-border-radius: 2px;
    --default-padding: 40px;
    --default-padding-small: 20px;
    --default-margin: 20px;
    --default-margin-small: 10px;
    --default-margin-large: 30px;
    --ui-height: 4rem;
    --ui-height-large: 5rem;
    --ui-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 5%);
    --ui-border-radius: 2px;
    --ui-padding: 40px;
    --ui-padding-small: 20px;
    --ui-margin: 20px;
    --ui-margin-small: 10px;
    --ui-margin-large: 30px;
    --ui-view-header-height: 5rem;
    --ui-view-body-padding-top: 60px;

    .panel {
        --min-width: 100%;
        --header-height: var(--ui-height-large);
    }

    .header {
        --height: var(--ui-main-header-height);
    }

    .ui-button {
        --height: var(--ui-height);
        --padding: 0 2.8rem;
        --min-width: 50px;

        .ui-loader {
            --border-radius: var(--ui-border-radius);
        }
    }

    .icon-button {
        --padding: 0 1.6rem;
    }

    .input {
        --height: var(--ui-height);
        --padding: 0.9rem 0.7rem;

        .label {
            --margin-bottom: 17px;
        }

        .input-icon {
            --margin-top: -2.7rem;
        }
    }

    .checkbox {
        --height: 1.6rem;

        .ui-button {
            --height: 1.6rem !important;
        }
    }

    .selectable {
        --height: var(--ui-height);
    }

    .ui-select,
    .ui-option {
        --height: var(--ui-height);
    }

    .ui-option {
        --checkbox-container-margin: 30px;
        --checkbox-margin: -30px;
    }

    .ui-dropdown {
        --item-height: 2.8rem;
        --item-padding-left: 24px;
        --item-hover-color: var(--ui-color-background);
        --item-hover-size: 86%;
        --item-border-bottom-color: var(--ui-color-border-second);
        --input-item-height: 3.7rem;
        --menu-border-radius: 2px;
        --menu-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 30%);
        --menu-border-color: var(--ui-color-border);
    }

    .ui-list {
        --row-height: 5rem;
        --header-height: 3rem;
        --cell-padding: 10px;
        --row-padding: 0px;
        --folder-opacity: 0.4;
        --toggle-icon-size: 14px;
        --toggle-icon-width: 20px;
    }

    .ui-toggle-switch {
        --height: 2rem;
        --width: 4rem;
        --switcher-width: 1.6rem;
        --switcher-height: 1.6rem;
        --toggle-border-size: 2px;
    }

    .ui-loader {
        --border-radius: 0;
    }

    .ui-tooltip {
        --padding: 10px;
    }

    .ui-flag {
        --size: 2rem;

        &.small {
            --size: 1.6rem;
        }

        &.tiny {
            --size: 1.2rem;
        }
    }

    .ui-notification {
        --padding: 1.2rem;
        --width: 30rem;
    }

    ui-accordion-item {
        .header {
            --padding: 1.5rem 1.5rem;
        }
    }
}

// Include paddings & border in width everywhere in our apps: TODO: *:after, *:before should also have this
* {
    box-sizing: border-box;
}

body {
    .ui-scrollbar {
        @include scrollbar.scrollbar(var(--default-color-grey));
    }
}
