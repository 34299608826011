$input-height: 2.6rem;

:where(:root:not([data-uinew])) {
    [ui-theme*='minimal'] {
        --default-height: 2.6rem;
        --ui-height: 2.6rem;

        .ui-button {
            --height: var(--ui-height);
            --padding: 0 1.5rem !important;
            --min-width: 5rem;
        }

        .icon-button {
            --min-width: 0px;
            --padding: 0 !important;
        }

        .input {
            --height: var(--ui-height);
            --padding: 0.7rem 0.7rem;

            .label {
                --margin-bottom: 5px;
            }

            &.textarea {
                --height: 2.6rem;
            }

            .input-icon {
                --margin-top: -2rem;
            }
        }

        .side-panel {
            --horizontal-padding: 1rem;
        }

        .selectable {
            --height: var(--ui-height);
        }

        .ui-dropdown {
            --item-height: 28px;
        }

        .ui-toggle-switch {
            --height: 1.4rem;
            --width: 2.8rem;
            --switcher-width: 1rem;
            --switcher-height: 1rem;
            --toggle-border-size: 2px;
        }

        .ui-tooltip {
            --padding: 5px 10px;
        }

        .ui-notification {
            --padding: 0.9rem 3.6rem 0.9rem 1rem;
            --width: 28rem;
        }

        .ui-option {
            --checkbox-container-margin: 17px;
            --checkbox-margin: -21px;
        }

        ui-accordion-item {
            .header {
                --padding: 1.5rem 1.5rem;
            }
        }
    }
}
