@use "sass:map";
@use '../animation-types';
@use '../config';

@keyframes #{config.$ANIMATION_NAME_PREFIX}#{map.get(animation-types.$ui-animations,'fade')}#{config.$ANIMATION_TYPE_IN} {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes #{config.$ANIMATION_NAME_PREFIX}#{map.get(animation-types.$ui-animations,'fade')}#{config.$ANIMATION_TYPE_OUT} {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
