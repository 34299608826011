:where(:root[data-uinew]) {
    --ui-font-family: var(--font-family-primary, 'Open Sans', sans-serif);

    /**
    * UI loader
    */
    --nui-loader-active-tick-opacity: 1;

    [data-uitheme="light"],
    &[data-uitheme="light"] {
        --nui-loader-active-tick: var(--fill-brand-primary-boldest);
    }

    [data-uitheme="dark"],
    &[data-uitheme="dark"] {
        --nui-loader-active-tick: var(--neutral-000);
    }

    /**
    * @deprecated OLD VARIABLES
    */
    --default-height: var(--numeric-1000);
    --default-border-radius: var(--numeric-050);
    --default-padding: var(--numeric-1000);
    --default-padding-small: var(--numeric-500);
    --default-margin: var(--numeric-500);
    --default-margin-small: var(--numeric-200);
    --default-margin-large: var(--numeric-800);
    --ui-height: var(--numeric-1000);
    --ui-height-large: var(--numeric-1200);
    --ui-border-radius: var(--numeric-050);
    --ui-padding: var(--numeric-1000);
    --ui-padding-small: var(--numeric-500);
    --ui-margin: var(--numeric-500);
    --ui-margin-small: var(--numeric-200);
    --ui-margin-large: var(--numeric-800);
    --ui-view-header-height: var(--numeric-1200);
    --ui-view-body-padding-top: var(--numeric-1600);

}
