@use "sass:string";
@use '../animation/config';
@use '../animation/animation-types';

// !! Is used in other projects as Stylus

/**
 * Add an animation to a CSS-block
 * @param name
 **/
@mixin ui-animation($name: 'fade', $type: 'in', $duration: 0.2s, $easing: ease, $delay: 0s) {
    /* animation: name duration timing-function delay iteration-count direction fill-mode play-state; */
    animation: string.unquote(config.$ANIMATION_NAME_PREFIX + $name + ANIMATION_TYPE_SEPARATOR + $type) $duration
        $easing $delay 1 normal forwards;
}
