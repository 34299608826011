@use "sass:map";
@use '../animation-types';
@use '../config';

@keyframes #{config.$ANIMATION_NAME_PREFIX}#{map.get(animation-types.$ui-animations,'slide-up')}#{config.$ANIMATION_TYPE_IN} {
    0%,
    80%,
    90%,
    100% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1) !important;
    }

    0% {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }

    35% {
        transform: translate3d(0, -10px, 0);
    }

    80% {
        transform: translate3d(0, 5px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes #{config.$ANIMATION_NAME_PREFIX}#{map.get(animation-types.$ui-animations,'slide-up')}#{config.$ANIMATION_TYPE_OUT} {
    0% {
        opacity: 1;
        transform: none;
    }

    20% {
        opacity: 1;
        transform: scale(0.95);
    }

    50% {
        opacity: 1;
        transform: scale(1.1);
    }

    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}
