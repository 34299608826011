@use "sass:map";
@use '../animation-types';
@use '../config';

@keyframes #{config.$ANIMATION_NAME_PREFIX}#{map.get(animation-types.$ui-animations,'fade-right')}#{config.$ANIMATION_TYPE_IN} {
    0% {
        opacity: 0;
        transform: translateX(-15px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes #{config.$ANIMATION_NAME_PREFIX}#{map.get(animation-types.$ui-animations,'fade-right')}#{config.$ANIMATION_TYPE_OUT} {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    100% {
        opacity: 0;
        transform: translateX(-15px);
    }
}
