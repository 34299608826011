@use 'sass:color';
@use 'sass:map';
@use '../colors';

:where(:root:not([data-uinew])) [ui-theme],
:where(:root:not([data-uinew])) body [ui-theme='default'],
:where(:root:not([data-uinew])) {
    --default-background-color: var(--default-color-white-off);
    --default-surface-color: var(--default-color-white);
    --default-secondary-surface-color: var(--default-color-grey-light);
    --default-text-color: var(--default-color-black-off);
    --default-border-color: var(--default-color-grey);
    --default-light-border-color: var(--default-color-grey-semilight);
    --default-primary-color: var(--default-color-blue);
    --default-success-color: var(--default-color-blue);
    --default-alert-color: var(--default-color-red);
    --default-warning-color: var(--default-color-gold);
    --default-active-color: var(--default-color-blue);
    --default-focus-color: var(--default-color-blue);
    --default-selection-color: var(--default-color-blue-semilight);
    --default-primary-text-color: var(--default-color-white);
    --default-secondary-text-color: var(--default-color-grey-dark);
    --default-backdrop-color: var(--default-color-white-off);
    --default-backdrop-opacity: 0.9;
    --default-disabled-opacity: 0.4;
    --ui-color-primary: var(--ui-static-color-blue);
    --ui-color-primary-second: var(--ui-static-color-midnight);
    --ui-color-background: var(--ui-static-color-grey-96);
    --ui-color-background-second: var(--ui-static-color-white);
    --ui-color-surface: var(--ui-static-color-white);
    --ui-color-surface-second: var(--ui-static-color-grey-98);
    --ui-color-surface-hovered: var(--ui-static-color-grey-93);
    --ui-color-surface-backdrop: var(--ui-static-color-grey-97);
    --ui-color-surface-selected: var(--ui-static-color-grey-92);
    --ui-color-surface-highlight: var(--ui-static-color-blue-mist);
    --ui-color-surface-warning: var(--ui-static-color-sand);
    --ui-color-surface-alert: var(--ui-static-color-marshmallow);
    --ui-color-surface-row-selected: var(--ui-static-color-waterfall);
    --ui-color-surface-table: var(--ui-static-color-grey-96);
    --ui-color-surface-dropzone: #c1ddff;
    --ui-color-border: var(--ui-static-color-grey-84);
    --ui-color-border-second: var(--ui-static-color-grey-93);
    --ui-color-border-highlight: var(--ui-static-color-arctic);
    --ui-color-border-hover: var(--ui-static-color-grey-71);
    --ui-color-text: var(--ui-static-color-grey-21);
    --ui-color-text-second: var(--ui-static-color-grey-71);
    --ui-color-text-third: var(--ui-static-color-grey-86);
    --ui-color-text-discrete: var(--ui-static-color-grey-61);
    --ui-color-text-disabled: var(--ui-static-color-grey-71);
    --ui-color-text-inverted: var(--ui-static-color-white);
    --ui-color-text-status-green: var(--ui-static-color-grass);
    --ui-color-text-status-orange: var(--ui-static-color-golden);
    --ui-color-first: var(--ui-static-color-grey-21);
    --ui-color-first-inverted: var(--ui-static-color-white);
    --ui-color-second: var(--ui-static-color-grey-71);
    --ui-color-third: var(--ui-static-color-grey-86);
    --ui-color-white: var(--ui-static-color-white);
    --ui-color-black: var(--ui-static-color-black);
    --ui-color-grey-21: var(--ui-static-color-grey-21);
    --ui-color-grey-61: var(--ui-static-color-grey-61);
    --ui-color-grey-71: var(--ui-static-color-grey-71);
    --ui-color-grey-84: var(--ui-static-color-grey-84);
    --ui-color-grey-86: var(--ui-static-color-grey-86);
    --ui-color-grey-89: var(--ui-static-color-grey-89);
    --ui-color-grey-92: var(--ui-static-color-grey-92);
    --ui-color-grey-93: var(--ui-static-color-grey-93);
    --ui-color-grey-94: var(--ui-static-color-grey-94);
    --ui-color-grey-95: var(--ui-static-color-grey-95);
    --ui-color-grey-96: var(--ui-static-color-grey-96);
    --ui-color-grey-97: var(--ui-static-color-grey-97);
    --ui-color-grey-98: var(--ui-static-color-grey-98);
    --ui-color-grey-99: var(--ui-static-color-grey-99);
    --ui-color-success: var(--ui-color-primary);
    --ui-color-alert: var(--ui-static-color-deep-red);
    --ui-color-warning: var(--ui-static-color-golden);
    --ui-color-active: var(--ui-color-primary);
    --ui-color-hover: var(--ui-static-color-grey-21);
    --ui-color-disabled: var(--ui-static-color-grey-98);
    --ui-color-disabled-second: var(--ui-static-color-grey-92);
    --ui-color-focus: var(--ui-color-primary);
    --ui-color-selection: var(--ui-static-color-arctic);
    --ui-color-selected-background: var(--ui-static-color-blue-mist);
    --ui-color-selected-border: var(--ui-static-color-sky);
    --ui-disabled-opacity: 0.4;
    --ui-color-additional-yellow: var(--ui-static-color-yellow);
    --ui-color-additional-olive: var(--ui-static-color-olive);
    --ui-color-additional-sky: var(--ui-static-color-sky);
    --ui-color-additional-rain: var(--ui-static-color-rain);
    --ui-color-additional-aqua: var(--ui-static-color-aqua);
    --ui-color-additional-salmon: var(--ui-static-color-salmon);
    --ui-color-additional-purple: var(--ui-static-color-purple);
    --ui-color-additional-lavender: var(--ui-static-color-lavender);
    --ui-color-additional-sand: var(--ui-static-color-sand);
    --ui-color-additional-marshmallow: var(--ui-static-color-marshmallow);
    --ui-color-additional-peach: var(--ui-static-color-peach);
    --ui-color-additional-coral: var(--ui-static-color-coral);
    --ui-color-additional-caramel: var(--ui-static-color-caramel);
    --ui-color-additional-almond: var(--ui-static-color-almond);

    .ui-header {
        --background-color: var(--ui-color-surface);
        --text-color: var(--ui-color-text);
    }

    .ui-panel {
        --background-color: var(--ui-color-surface);
        --border-color: var(--ui-color-border);
        --header-background-color: var(--ui-color-surface-second);
    }

    .loader {
        --color: var(--ui-color-text);
        --opacity: 0.5;
    }

    .ui-button {
        --background-color: var(--ui-color-white);
        --text-color: var(--ui-color-primary);
        --border-color: var(--ui-color-border);
        --border-color-hover: var(--ui-color-grey-71);
        --active-background-color: #{color.adjust(map.get(colors.$ui-color, 'white'), $lightness: -1%)};
        --active-text-color: #{color.mix(
                map.get(colors.$ui-color, 'blue'),
                map.get(colors.$ui-color, 'white'),
                70%
            )};

        .ui-loader {
            --background-color: var(--background-color);
            --circle-color: var(--text-color);
        }

        &.primary {
            --background-color: var(--ui-color-primary);
            --text-color: var(--ui-color-white);
            --border-color-hover: var(--ui-color-white);
            --active-background-color: var(--ui-color-primary-second);
            --active-text-color: #{color.mix(
                    map.get(colors.$ui-color, 'white'),
                    map.get(colors.$ui-color, 'blue'),
                    70%
                )};

            &:hover {
                background-color: #{color.adjust(map.get(colors.$ui-color, 'blue'), $lightness: -20%)};
            }

            &.disabled {
                --text-color: var(--ui-color-text-disabled);
                --background-color: var(--ui-color-disabled-second);
                --border-color: var(--ui-color-disabled-second);
            }

            &:active {
                transform: scale(0.98);
            }
        }

        &.discrete {
            --text-color: var(--ui-color-grey-61);
            --active-text-color: #{color.adjust(map.get(colors.$ui-color, 'grey-61'), $lightness: -10%)};
        }

        &.disabled {
            --text-color: var(--ui-color-text-disabled);
            --background-color: var(--ui-color-disabled-second);
            --border-color: var(--ui-color-disabled-second);
        }
    }

    .ui-dialog-backdrop {
        --background-color: #{color.adjust(map.get(colors.$ui-color, 'black'), $alpha: -0.68)};
    }

    .ui-drop-zone {
        --border-color: var(--ui-color-primary);
        --border-opacity: 0.5;
    }

    .ui-dropdown {
        --divider-color: var(--ui-color-surface-second);

        .ui-dropdown-item {
            &:hover {
                .icon {
                    --color: var(--ui-static-color-grey-21);
                }
            }

            .icon {
                --color: var(--ui-static-color-grey-84);
            }
        }
    }

    .ui-loader {
        --background-color: var(--ui-color-background);
        --circle-color: var(--ui-color-primary);
    }

    .ui-list {
        --icon-color: --ui-color-grey-71;
        --folder-opacity: 0.4;
        --background: var(--ui-color-surface);
        --background-alt: var(--ui-color-surface-second);

        &:not(.checkboxes) .selected {
            --background: var(--ui-static-color-blue-mist);
            --background-alt: #{color.adjust(map.get(colors.$ui-color, 'blue-mist'), $lightness: -2%)};
        }
    }

    .ui-popover {
        --background-color: var(--ui-color-surface);
    }
}

body {
    background-color: var(--ui-static-color-grey-96);
    color: var(--ui-static-color-grey-21);
}
