@use "sass:color";
@use "sass:map";
@use '../colors';

:where(:root:not([data-uinew])) {
    [ui-theme*='dark'] {
        --default-background-color: var(--default-color-black-off-dark);
        --default-surface-color: var(--default-color-black-off-semi);
        --default-text-color: var(--default-color-grey);
        --default-border-color: var(--default-color-black-off);
        --default-primary-color: var(--default-color-grey);
        --default-success-color: var(--default-color-black-off);
        --default-alert-color: var(--default-color-red);
        --default-warning-color: var(--default-color-gold);
        --default-active-color: var(--default-color-white);
        --default-focus-color: var(--default-color-white);
        --default-primary-text-color: var(--default-color-white);
        --default-secondary-text-color: var(--default-color-grey-dark);
        --default-secondary-surface-color: var(--default-color-black-off-semi);
        --ui-color-background: #303030;
        --ui-color-background-second: var(--ui-static-color-black-off-semi);
        --ui-color-surface: #555555;
        --ui-color-surface-second: #{color.adjust(#555555, $lightness: -20%)};
        --ui-color-border: var(--ui-static-color-grey-21);
        --ui-color-border-second: var(--ui-static-color-grey-21);
        --ui-color-primary: var(--ui-static-color-blue);
        --ui-color-primary-second: var(--ui-static-color-midnight);
        --ui-color-text: var(--ui-static-color-white);
        --ui-color-text-inverted: var(--ui-static-color-black);
        --ui-color-text-second: var(--ui-static-color-grey-86);
        --ui-color-text-third: var(--ui-static-color-grey-71);
        --ui-color-text-disabled: var(--ui-static-color-grey-61);
        --ui-color-first: var(--ui-static-color-grey-21);
        --ui-color-first-inverted: var(--ui-static-color-white);
        --ui-color-second: var(--ui-static-color-grey-71);
        --ui-color-third: var(--ui-static-color-grey-86);
        --ui-color-white: var(--ui-static-color-white);
        --ui-color-black: var(--ui-static-color-black);
        --ui-color-grey-21: var(--ui-static-color-grey-21);
        --ui-color-grey-61: var(--ui-static-color-grey-61);
        --ui-color-grey-71: var(--ui-static-color-grey-71);
        --ui-color-grey-84: var(--ui-static-color-grey-84);
        --ui-color-grey-86: var(--ui-static-color-grey-86);
        --ui-color-grey-89: var(--ui-static-color-grey-89);
        --ui-color-grey-92: var(--ui-static-color-grey-92);
        --ui-color-grey-93: var(--ui-static-color-grey-93);
        --ui-color-grey-94: var(--ui-static-color-grey-94);
        --ui-color-grey-95: var(--ui-static-color-grey-95);
        --ui-color-grey-96: var(--ui-static-color-grey-96);
        --ui-color-grey-97: var(--ui-static-color-grey-97);
        --ui-color-grey-98: var(--ui-static-color-grey-98);
        --ui-color-grey-99: var(--ui-static-color-grey-99);
        --ui-color-success: var(--ui-color-primary);
        --ui-color-alert: var(--ui-static-color-coral);
        --ui-color-warning: var(--ui-static-color-golden);
        --ui-color-active: var(--ui-color-primary);
        --ui-color-hover: var(--ui-static-color-grey-21);
        --ui-color-disabled: var(--ui-static-color-grey-21);
        --ui-color-disabled-second: var(--ui-static-color-grey-92);
        --ui-disabled-opacity: 0.4;
        --ui-color-focus: var(--ui-static-color-arctic);
        --ui-color-selection: var(--ui-static-color-arctic);
        --ui-color-selected-background: var(--ui-static-color-grey-21);
        --ui-color-selected-border: var(--ui-color-primary);

        .ui-header {
            --background-color: var(--ui-color-background-second);
            --text-color: var(--ui-color-text);
        }

        .ui-button {
            --background-color: var(--ui-color-surface);
            --text-color: var(--ui-color-white);
            --border-color: var(--ui-color-border);
            --active-background-color: #{color.adjust(map.get(colors.$ui-color, 'black-off-semi'), $lightness: -1%)};
            --active-text-color: #{color.mix( map.get(colors.$ui-color, 'white'), map.get(colors.$ui-color, 'black-off-semi'), 70%)

        }

        ;

        .ui-loader {
            --background-color: var(--background-color);
            --circle-color: var(--text-color);
        }

        &:hover {
            background-color: #{color.adjust(map.get(colors.$ui-color, 'blue'), $lightness: -20%)};
        }

        &.primary:not(.disabled) {
            --background-color: var(--ui-color-primary);
            --text-color: var(--ui-color-white);
            --active-background-color: var(--ui-static-color-midnight);
            --active-text-color: var(--ui-static-color-grey-86);
        }

        &.discrete:not(.disabled) {
            --text-color: #{color.mix(map.get(colors.$ui-color, 'white'), map.get(colors.$ui-color, 'blue'), 70%)};
            --active-text-color: #{color.mix(map.get(colors.$ui-color, 'white'), map.get(colors.$ui-color, 'blue'), 80%)};
        }

        &:active {
            transform: scale(0.98);
        }
    }

    .ui-dialog-backdrop {
        --backdrop-color: var(--ui-color-black);
    }

    .ui-drop-zone {
        --border-color: #{color.adjust(map.get(colors.$ui-color, 'white'), $alpha: -0.5)};
    }
}
}
