:where(:root:not([data-uinew])) [ui-theme],
:where(:root:not([data-uinew])) body [ui-theme='default'],
:where(:root:not([data-uinew])) {
    // OLD VARIABLES - Will be deprecated soon!
    --default-font-size: 1.2rem;
    --default-font-weight: 400;
    --default-line-height: 1.33;
    --default-font-family:
        'Open Sans', -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --default-header-font-family: var(--default-font-family);

    // Font weights
    --default-font-weight-normal: var(--default-font-weight);
    --default-font-weight-light: 200;
    --default-font-weight-bold: 600;

    // NEW VARIABLES
    --ui-font-size: 1.2rem;
    --ui-font-weight: 400;
    --ui-line-height: 1.33;
    --ui-font-family: 'Open Sans', sans-serif;
    --ui-header-font-family: var(--ui-font-family);
    --ui-font-weight-normal: var(--ui-font-weight);
    --ui-font-weight-light: 200;
    --ui-font-weight-semibold: 600;
    --ui-font-weight-bold: 700;

    .panel {
        --font-weight: 500;
    }

    .ui-button {
        --font-size: 1.1rem;
        --font-weight: var(--ui-font-weight-bold);
        --icon-font-size: 1.4rem;
    }

    .input {
        --font-size: var(--ui-font-size);
        --line-height: var(--ui-line-height);
        --font-weight: var(--ui-font-weight-bold);
    }

    .icon {
        --font-size: inherit;
    }

    .ui-list {
        --icon-font-size: 1.4rem;
    }

    .ui-dropdown {
        .icon {
            --font-size: 1.4rem;
            --color: var(--ui-color-grey);
        }
    }
}

body {
    font-size: var(--ui-font-size);
    line-height: var(--ui-line-height);
    font-family: var(--ui-font-family);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--ui-font-family);
    }

    a {
        color: var(--ui-color-primary);
        transition: 0.2s color ease;

        &:hover {
            color: var(--ui-color-text);
        }

        &:focus {
            outline: none;
        }
    }

    .label {
        font-size: 1.4rem;
        font-weight: var(--ui-font-weight-bold);
    }

    .menu-group {
        font-weight: 500;
    }
}
