:where(:root:not([data-uinew])) {
    .ui-dialog-backdrop {
        background-color: var(--background-color);
    }
}

:where(:root[data-uinew]) {
    .ui-dialog-backdrop {
        background-color: var(--surface-overlay);
    }
}
