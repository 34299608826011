@mixin scrollbar($color: rgba(0, 0, 0, 0.25)) {
    &::-webkit-scrollbar {
        height: 12px;
        width: 5px;
        margin-right: 5px;
        background: transparent;

        &:horizontal {
            height: 5px;
            width: 12px;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: $color;
        border-radius: 1ex;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    @document url-prefix() {
        scrollbar-width: thin;
    }

    [data-uinew] & {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 112px;
            min-height: 150px;
            background: var(--fill-neutral-subtle, #ededed);
        }

        &::-webkit-scrollbar-thumb:hover,
        &::-webkit-scrollbar-thumb:active {
            background: var(--fill-neutral-bold, #9e9e9e);
        }
    }
}
